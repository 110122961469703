import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../assets/css/style.css'
import '../assets/css/fonts.css'
import '../assets/css/media-query.css'
import '../assets/css/variables.css'

// icon
import { FaCircleXmark } from 'react-icons/fa6'
import { IoMdExit } from 'react-icons/io'
import { IoMenu } from 'react-icons/io5'
import {
  FaShoppingBasket,
  FaChartBar,
  FaQrcode,
  FaStore,
  FaCommentDots,
  FaRegCommentDots,
  FaAngleDown,
  FaAngleLeft,
  FaAngleRight,
  FaShoppingBag,
  FaBook,
  FaArrowLeft,
  FaCaretSquareDown,
  FaEnvelopeOpenText,
  FaEnvelope,
  FaCaretSquareLeft,
  FaCaretSquareRight,
} from 'react-icons/fa'

import banner from '../assets/images/dashboard-banner-background.png'
import logo from '../assets/images/logo.png'
import lastOrder from '../assets/images/last-order-img.png'
import headphoneSvg from '../assets/images/headphone-headphones-svgrepo-com.svg'
import serviceBox from '../assets/images/service-box.png'

import dashboardEmpty from '../assets/images/dashboard-empty-img.png'

function Orders() {
  const navigate = useNavigate()

  return (
    <div className='body-white-color-2'>
      <div className='page-main-contaier body-white-color-2'>
        {/* <!--=========Main============--> */}
        <main className='main'>
          {/* <!--============Dashboard-section--> */}
          <section className='dashboard-sectoin'>
            {/* <!--setting-container--> */}
            <div className='container'>
              {/* <!--dashboard-content---> */}
              <div className='dashboard-content-wrapper'>
                {/* <!--==========input-for-controlling-the-funtionality========--> */}
                <input
                  type='checkbox'
                  className='side-nav-input-v1'
                  id='side-nav-input-v1'
                  hidden
                />
                {/* <!---side-nav--> */}
                <div className='dashboard-side-nav mini-section-box-style'>
                  {/* <!--icon--> */}
                  <label
                    for='side-nav-input-v1'
                    className='side-nav-close-label'
                  >
                    {/* <!--icon--> */}
                    <FaCircleXmark className='fa-solid fa-xmark' />
                  </label>
                  {/* <!--logo--> */}
                  <div className='side-nav-logo-wrapper'>
                    {/* <!--img--> */}
                    <img src={logo} alt='img' className='side-nav-logo' />
                    {/* <!--text--> */}
                    <h4 className='side-nav-logo-text'>زود گیفت</h4>
                  </div>
                  {/* <!--list--> */}
                  <ul className='side-nav-list'>
                    {/* <!--link-item--> */}
                    <a href='/dashboard' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item '>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}
                        <IoMenu className='fa-solid fa-table-list side-nav-item-icon' />
                        {/* <!--text--> */}
                        <p className='side-nav-text'>داشبورد</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='/dashboard/orders' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item active-side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaShoppingBasket className='fa-solid fa-basket-shopping side-nav-item-icon' />
                        {/* <!--text--> */}
                        <p className='side-nav-text'>مشاهده سفارشات</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='#' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item '>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaChartBar className='fa-solid fa-chart-line side-nav-item-icon' />

                        {/* <!--text--> */}
                        <p className='side-nav-text'>تراکنش اخیر</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a
                      href='/dashboard/authentication'
                      className='side-nav-link'
                    >
                      {/* <!--item--> */}
                      <li className='side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaQrcode className='fa-solid fa-qrcode side-nav-item-icon' />
                        {/* <!--text--> */}
                        <p className='side-nav-text'>احراز هویت</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='#' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaStore className='fa-solid fa-store side-nav-item-icon' />

                        {/* <!--text--> */}
                        <p className='side-nav-text'>سفارش عمده</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='/dashboard/support' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaCommentDots className='fa-solid fa-comment-dots side-nav-item-icon' />
                        {/* <!--text--> */}
                        <p className='side-nav-text'>تیکت پشتیبانی</p>
                      </li>
                    </a>
                  </ul>
                  <div className='side-nav-down-link-wrapper'>
                    {/* <!--link--> */}
                    <a href='/' className='side-nav-link-v2'>
                      بازگشت به فروشگاه
                    </a>
                  </div>
                </div>
                {/*  <!--dashboard-content--> */}
                <div className='dashboard-content'>
                  {/* <!--==========input-for-controlling-the-funtionality========--> */}
                  <input
                    type='radio'
                    name='status-radio-input'
                    id='empty-status'
                    className='status-input'
                    checked
                  />
                  <input
                    type='radio'
                    name='status-radio-input'
                    id='in-progress-status'
                    className='status-input'
                  />
                  <input
                    type='radio'
                    name='status-radio-input'
                    id='finished-status'
                    className='status-input'
                  />
                  <input
                    type='radio'
                    name='status-radio-input'
                    id='rejected-status'
                    className='status-input'
                  />
                  <input
                    type='radio'
                    name='status-radio-input'
                    id='last-payment-status'
                    className='status-input'
                  />
                  {/* <!--dashbord-top--> */}
                  <div className='dashboard-top-wrapper mini-section-box-style'>
                    {/* <!--right-side--> */}
                    <div className='dashboard-top-right-wrapper'>
                      {/* <!--link--> */}
                      <a
                        href='#'
                        className='dashboard-top-link dashboard-top-link-mobile'
                      >
                        {/* <!--icon--> */}
                        <IoMdExit className='fa-solid fa-right-from-bracket' />
                        خروج
                      </a>
                      {/* <!--lable--> */}
                      <label
                        for='side-nav-input-v1'
                        className='side-nav-open-label'
                      >
                        پنل کاربری
                      </label>
                    </div>
                    {/* <!--left-side--> */}
                    <div className='dashboard-top-left-wrapper'>
                      {/* <!--text-box--> */}
                      <div className='dashboard-left-text-box'>
                        {/* <!--link--> */}
                        <a href='#' className='dashboard-top-link'>
                          {/* <!--icon--> */}
                          <FaRegCommentDots className='fa-regular fa-comment-dots' />
                          پشتیبانی
                        </a>
                        <p className='dashboard-top-text'>
                          {/* <!--text--> */}
                          <span className='dashboard-top-text-span'>
                            ۰۹۱۶۰۲۶۵۶۶۱
                          </span>
                          {/* <!--line--> */}
                          <span className='dashboard-top-text-line-span'></span>
                          {/* <!--text--> */}
                          <span className='dashboard-top-text-span'>
                            محمدرضا عبادی
                          </span>
                        </p>
                      </div>
                      {/* <!--logo--> */}
                      <div className='dashboard-top-logo-wrapper'>
                        {/* <!--logo--> */}
                        <img
                          src={logo}
                          alt='img'
                          className='dashboard-log-img'
                        />
                      </div>
                    </div>
                  </div>
                  {/* <!--orders-status-box--> */}
                  <div className='orders-status-wrapper mini-section-box-style'>
                    {/* <!--label--> */}
                    <label
                      for='in-progress-status'
                      className='orders-status-label'
                    >
                      {/* <!--text--> */}
                      <p className='dashboard-text'>
                        {/* <!--dashboard-icon--> */}
                        <span className='dashboard-circle-icon dashboard-circle-icon-orange'></span>
                        در حال انجام ( ۷ )
                      </p>
                    </label>
                    {/* <!--label--> */}
                    <label
                      for='finished-status'
                      className='orders-status-label'
                    >
                      {/* <!--text--> */}
                      <p className='dashboard-text'>
                        {/* <!--dashboard-icon--> */}
                        <span className='dashboard-circle-icon dashboard-circle-icon-green'></span>
                        تکمیل شده ( ۴ )
                      </p>
                    </label>
                    {/* <!--label--> */}
                    <label
                      for='rejected-status'
                      className='orders-status-label'
                    >
                      {/* <!--text--> */}
                      <p className='dashboard-text'>
                        {/* <!--dashboard-icon--> */}
                        <span className='dashboard-circle-icon dashboard-circle-icon-red'></span>
                        مسترد شده ( ۰ )
                      </p>
                    </label>
                    {/* <!--label--> */}
                    <label
                      for='last-payment-status'
                      className='orders-status-label'
                    >
                      {/* <!--text--> */}
                      <p className='dashboard-text'>
                        {/* <!--dashboard-icon--> */}
                        <FaChartBar className='fa-solid fa-chart-line dashboard-icon-v2' />
                        تراکنشات اخیر ( ۱۰ )
                      </p>
                    </label>
                  </div>
                  {/* <!--dashboard-see-orders-sections--> */}
                  <section className='dashboard-see-orders-sections empty-status'>
                    {/* <!--content--> */}
                    <div className='empty-status-content mini-section-box-style'>
                      {/* <!--orders-titles--> */}
                      <div className='last-order-title-wrapper'>
                        {/* <!--title--> */}
                        <h5 className='h5-dashboard-title'>شماره سفارش</h5>
                        {/* <!--title--> */}
                        <h5 className='h5-dashboard-title'>محصول</h5>
                        {/* <!--title--> */}
                        <h5 className='h5-dashboard-title'>جزئیات سفارش</h5>
                      </div>
                      {/* <!--line--> */}
                      <span className='under-line-v1'></span>
                      {/* <!--banner--> */}
                      <div className='empty-status-banner-wrapper'>
                        {/* <!--img--> */}
                        <img
                          src={dashboardEmpty}
                          alt='img'
                          className='empty-status-img'
                        />
                      </div>
                      {/* <!--text--> */}
                      <p className='empty-status-text'>
                        {/* <!--span--> */}
                        <span className='empty-status-span-v1'>
                          همه چی آرومه سفارشی در حال انجام ندارید
                        </span>
                        {/* <!--span--> */}
                        <span className='empty-status-span-v2'>
                          اگر سفارشی دارید تیم فروش زود گیفت آماده خدمات رسانی
                          به شما عزیزان می باشد
                        </span>
                      </p>
                      {/* <!--button--> */}
                      <a
                        href='/index-2.html'
                        className='dashboard-see-orders-btn'
                      >
                        رفتن به فروشگاه
                      </a>
                    </div>
                  </section>
                  {/* <!--dashboard-see-orders-sections--> */}
                  <section className='dashboard-see-orders-sections in-progress-status'>
                    {/* <!--last-order-main-wrapper--> */}
                    <div className='last-order-main-content-wrapper mini-section-box-style'>
                      {/* <!--last-orders--> */}
                      <div className='last-orders-wrapper last-orders-wrapper-v2'>
                        {/* <!--orders-titles--> */}
                        <div className='last-order-title-wrapper'>
                          {/* <!--title--> */}
                          <h5 className='h5-dashboard-title'>شماره سفارش</h5>
                          {/* <!--title--> */}
                          <h5 className='h5-dashboard-title'>محصول</h5>
                          {/* <!--title--> */}
                          <h5 className='h5-dashboard-title'>جزئیات سفارش</h5>
                        </div>
                        {/* <!--line--> */}
                        <span className='under-line-v1'></span>
                        {/* <!--====input-for-controlling-the-funtionality=====--> */}
                        <input
                          type='checkbox'
                          hidden
                          className='last-orders-input'
                          id='last-orders-input-1'
                        />
                        {/* <!--order-item--> */}
                        <div className='last-order-item'>
                          {/* <!--text--> */}
                          <p className='last-order-text-v1'>zg-۰۰۰۱</p>
                          {/* <!--product-box--> */}
                          <div className='last-order-product-wrapper'>
                            {/* <!--img--> */}
                            <img
                              src={lastOrder}
                              alt='img'
                              className='last-order-img'
                            />
                            {/* <!--text--> */}
                            <p className='last-order-text-v2'>
                              لایسنس اورجینال ادوبی فتوشاپ سه ماهه
                            </p>
                          </div>
                          {/* <!--label--> */}
                          <label
                            for='last-orders-input-1'
                            className='last-order-label last-order-text-v1'
                          >
                            مشاهده جزئیات
                            {/* <!--icon--> */}
                            <FaCaretSquareDown className='fa-regular fa-square-caret-down' />
                          </label>
                        </div>
                        {/* <!--dorp-box--> */}
                        <div className='last-order-item-drop-box order-drop-box-1'>
                          {/* <!--title--> */}
                          <div className='last-order-drop-top-wrapper'>
                            {/* <!--text--> */}
                            <p className='dashboard-text'>
                              {/* <!--icon--> */}
                              <span className='dashboard-circle-icon dashboard-circle-icon-orange'></span>
                              وضعیت سفارش : در حال انجام
                            </p>
                            {/* <!--text--> */}
                            <p className='dashboard-text'>
                              مبلغ پرداختی: ۱,۲۵۰,۰۰۰ تومانء
                            </p>
                            {/* <!--text--> */}
                            <p className='dashboard-text'>
                              تاریخ ثبت سفارش :۲۳/۰۱/۱۴۰۲
                            </p>
                          </div>
                          {/* <!--line--> */}
                          <span className='under-line-v1'></span>
                          {/* <!--content-wrapper--> */}
                          <div className='last-order-item-drop-box-content-wrapper'>
                            {/* <!--side-line--> */}
                            <span className='dashboard-side-line'></span>
                            {/* <!--right--> */}
                            <div className='last-order-drop-box-right'>
                              {/* <!--text--> */}
                              <p className='dashboard-text last-order-drop-down-text'>
                                رمز ارسالی :
                                <span className='dashboard-text-span'>
                                  {' '}
                                  reza79reza{' '}
                                </span>
                              </p>
                              {/* <!--text--> */}
                              <p className='dashboard-text'>
                                ایمیل ارسالی :
                                <span className='dashboard-text-span'>
                                  {' '}
                                  mrmteamm99@gmail.com{' '}
                                </span>
                              </p>
                            </div>
                            {/* <!--left--> */}
                            <div class='last-order-drop-box-left'>
                              {/* <!--text-box--> */}
                              <div className='last-order-left-text-box'>
                                <p className='dashboard-text-v3'>
                                  <span className='last-order-dashbord-text-span'>
                                    از صبوری شما متشکریم
                                  </span>
                                  بازه زمانی تحویل محصول شما ۱ الی ۲۴ می باشد
                                </p>
                              </div>
                              {/* <!--last-order-gift--> */}
                              <div className='last-order-gift-wrapper'></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!--pagination-wrapper--> */}
                    <div className='order-bottom-wrapper-v3'>
                      {/* <!--right--> */}
                      <div className='order-bottom-wrapper-right'>
                        {/* <!--text--> */}
                        <p className='dashboard-text dashboard-text-gray'>
                          نمایش
                        </p>
                        {/* <!--========input-for-controlling-the-funtionality=======--> */}
                        <input
                          type='checkbox'
                          className='order-bottom-input'
                          id='order-bottom-input-1'
                          hidden
                        />
                        {/* <!--drop-down-lable--> */}
                        <label
                          for='order-bottom-input-1'
                          className='order-bottom-label'
                        >
                          {/* <!--text--> */}
                          <p className='dashboard-text dashboard-text-gray order-lable-text'>
                            4{/* <!--icon--> */}
                            <FaAngleDown className='fa-solid fa-angle-down' />
                          </p>
                          {/* <!--dorp-down--> */}
                          <ul className='order-bottom-drop-down'>
                            {/* <!--item--> */}
                            <li className='order-drop-down-item'>1</li>
                            {/* <!--item--> */}
                            <li className='order-drop-down-item'>2</li>
                            {/* <!--item--> */}
                            <li className='order-drop-down-item'>3</li>
                            {/* <!--item--> */}
                            <li className='order-drop-down-item'>4</li>
                          </ul>
                        </label>
                      </div>
                      {/* <!--pagination--> */}
                      <ul className='pagination-v3'>
                        {/* <!--btn--> */}
                        <li className='pagination-btn-v3'>
                          <FaAngleLeft className='fa-solid fa-angles-left' />
                        </li>
                        {/* <!--item--> */}
                        <li className='pagination-item-v3 pagination-v3-active'>
                          1
                        </li>
                        {/* <!--item--> */}
                        <li className='pagination-item-v3'>2</li>
                        {/* <!--item--> */}
                        <li className='pagination-item-v3'>3</li>
                        {/* <!--item--> */}
                        <li className='pagination-item-v3'>4</li>
                        {/* <!--btn--> */}
                        <li className='pagination-btn-v3'>
                          <i className='fa-solid fa-angles-right'></i>
                          <FaAngleRight className='fa-solid fa-angles-right' />
                        </li>
                      </ul>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <ToastContainer toastClassName='toast-right' />
    </div>
  )
}

export default Orders
