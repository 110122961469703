import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../assets/css/style.css'
import '../assets/css/fonts.css'
import '../assets/css/media-query.css'
import '../assets/css/variables.css'

import logo02 from '../assets/images/logo.png'
import bank1 from '../assets/images/bank-img-1.png'
import bank2 from '../assets/images/bank-img-2.png'
import bank3 from '../assets/images/bank-img-3.png'

import {
  FaShoppingBasket,
  FaPhoneVolume,
  FaStore,
  FaSmileBeam,
  FaClock,
  FaCaretSquareDown,
  FaPenSquare,
  FaChevronDown,
  FaCheck,
} from 'react-icons/fa'

function Basket() {
  const [cartData, setCartData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [isChecked, setIsChecked] = useState(false)
  const [linkPayment, setLinkPayment] = useState('')

  const [productCardNumber, setProductCardNumber] = useState(0)

  // cart info

  useEffect(() => {
    const fetchCartData = async () => {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://api.zoodgift.com/api/cart',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        },
      }

      try {
        const response = await axios.request(config)
        if (response.status === 404 || response.data.statusCode === 404) {
          toast.error(response.data.message)
        } else {
          setCartData(response.data)
          setProductCardNumber(response.data.data.products.length)
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          toast.error('سبد محصول شما خالی است')
        } else {
          toast.error('خطا در دریافت داده‌های سبد خرید')
        }
        console.log(error)
      } finally {
        setLoading(false)
      }
    }

    fetchCartData()
  }, [])

  // تایید قوانین

  useEffect(() => {
    const fetchPaymentLink = async () => {
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://api.zoodgift.com/api/cart/payment',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
        },
      }

      try {
        const response = await axios.request(config)
        setLinkPayment(response.data.data.pay_url)
        toast.success('لینک پرداخت با موفقیت دریافت شد')
      } catch (error) {
        console.log(error)
        toast.error('خطا در دریافت لینک پرداخت')
      }
    }

    if (isChecked) {
      fetchPaymentLink()
    }
  }, [isChecked])

  const handlePayment = () => {
    if (!isChecked) {
      toast.error('شرایط و قوانین را تایید کنید')
      return
    }
    if (linkPayment) {
      window.location.href = linkPayment // کاربر را به لینک پرداخت هدایت می‌کند
    }
  }

  const increaseQuantity = (id) => {
    setCartData((prevCartData) => ({
      ...prevCartData,
      data: {
        ...prevCartData.data,
        products: prevCartData.data.products.map((product) =>
          product.id === id
            ? { ...product, number: product.number + 1 }
            : product
        ),
      },
    }))
  }

  const decreaseQuantity = (id) => {
    setCartData((prevCartData) => ({
      ...prevCartData,
      data: {
        ...prevCartData.data,
        products: prevCartData.data.products.map((product) =>
          product.id === id && product.number > 1
            ? { ...product, number: product.number - 1 }
            : product
        ),
      },
    }))
  }

  const toPersianNumber = (number) => {
    const persianDigits = '۰۱۲۳۴۵۶۷۸۹'
    return number.toString().replace(/\d/g, (digit) => persianDigits[digit])
  }

  if (loading) {
    return (
      <div className='loading-container'>
        <div className='loading-spinner'></div>
      </div>
    )
  }

  return (
    <div className='page-main-container body-white-color-2'>
      <main className='main' id='main'>
        {/* <!--=========order-items-section=======--> */}
        <section className='order-items-section'>
          <div className='container'>
            {/* <!--main-wrapper--> */}
            <div className='order-items-content-wrapper'>
              {/* <!--input-for-controlling-the-funtionality--> */}
              <input
                type='checkbox'
                className='long-term-input'
                id='long-item-input-1'
                hidden
              />
              {/* <!--title-box--> */}
              <div className='title-wrapper-v2'>
                {/* <!--right-wrapper--> */}
                <div className='title-wrapper-right'>
                  {/* <!--title--> */}
                  <h3 className='shop-basket-title'>
                    {/* <!--icon--> */}
                    <FaShoppingBasket className='fa-solid fa-basket-shopping shop-basket-title-icon' />
                    اقلام سفارش شما
                  </h3>
                  {/* <!--text--> */}
                  <p className='title-info-v2'>
                    {toPersianNumber(productCardNumber)} محصول در سبد خرید
                  </p>
                </div>
                {/* <!--left-wrapper--> */}
                <div className='title-wrapper-left'>
                  {/* <!--link--> */}
                  <a href='/' className='phone-link-v1 title-link-v2'>
                    {/* <!--icon--> */}
                    <FaPhoneVolume className='fa-solid fa-phone-volume title-link-icon-v2' />
                  </a>
                  {/* <!--link--> */}
                  <a href='/' className='phone-link-v1 title-link-v2'>
                    {/* <!--icon--> */}
                    <FaStore className='fa-solid fa-shop title-link-icon-v2' />
                  </a>
                </div>
              </div>
              {/* <!--column-title-wrapper--> */}
              <div className='shop-basket-titles-wrapper shop-basket-box-style'>
                {/* <!--text--> */}
                <h6 className='shop-basket-title-v1'>محصول</h6>
                {/* <!--text--> */}
                <h6 className='shop-basket-title-v1'>قیمت واحد</h6>
                {/* <!--text--> */}
                <h6 className='shop-basket-title-v1'>تعداد</h6>
                {/* <!--text--> */}
                <h6 className='shop-basket-title-v1'>قیمت نهایی</h6>
              </div>
              {/* <!--shop-basket-order-item--> */}
              <div>
                {cartData &&
                  cartData.data.products.map((product) => (
                    <div
                      key={product.id}
                      className='shop-basket-order-item shop-basket-box-style'
                    >
                      <div className='shop-basket-product-wrapper product-item-wrapper-v1'>
                        <img
                          src={logo02 || 'default-image-url'}
                          alt='img'
                          className='table-product-img'
                        />
                        <div className='product-text-box-v1'>
                          <h4 className='table-text-v1'>{product.title}</h4>
                          <p className='table-sub-text-v1'>
                            تحویل فوری
                            <i className='fa-regular fa-face-laugh-beam table-sub-text-v1-icon'></i>
                          </p>
                        </div>
                      </div>
                      <div className='product-price-wrapper-v1 product-item-wrapper-v1'>
                        <p className='table-text-v1 text-align-center'>
                          {toPersianNumber(product.price)} تومان
                        </p>
                      </div>
                      <div className='product-number-wrapper-v1 product-item-wrapper-v1'>
                        <div className='product-number-box-v1'>
                          <button
                            className='add-btn-v1 product-number-btn'
                            onClick={() => increaseQuantity(product.id)}
                          >
                            +
                          </button>
                          <p className='table-text-v1'>
                            {toPersianNumber(product.number)}
                          </p>
                          <button
                            className='mines-button-v1 product-number-btn'
                            onClick={() => decreaseQuantity(product.id)}
                          >
                            -
                          </button>
                        </div>
                      </div>
                      <div className='product-total-price-wrapper-v1 product-item-wrapper-v1'>
                        <p className='table-text-v1 text-align-center'>
                          {toPersianNumber(product.subtotal)} تومان
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
              {/* <!--shop-basket-order-item--> */}
              <div className='shop-basket-order-item long-term-order-item shop-basket-box-style shop-basket-'>
                {/* <!--product-wrapper--> */}
                {/* DropDown-محصول تایم دار */}
                {/* <!--product-wrapper--> */}
                <div className='shop-basket-product-wrapper product-item-wrapper-v1'>
                  {/* <!--img--> */}
                  <img src={logo02} alt='img' class='table-product-img' />
                  {/* <!--prduct-name-box--> */}
                  <div className='product-text-box-v1'>
                    {/* <!--name--> */}
                    <h4 className='table-text-v1 '>
                      گیفت کارت 100 دلاری پلی اسیتشن آمریکا
                    </h4>
                    <div className='table-bottom-text-wrapper'>
                      {/* <!--text--> */}
                      <p className='table-sub-text-v1'>
                        تحویل فوری
                        {/* <!--icon--> */}
                        <FaClock className='fa-regular fa-clock table-sub-text-v1-icon' />
                      </p>
                      {/* <!--label--> */}
                      <label
                        for='long-item-input-1'
                        className='sent-info-label'
                      >
                        مشاهده اطلاعات ارسالی
                        {/* <!--icon--> */}
                        <FaCaretSquareDown className='fa-regular fa-square-caret-down sent-info-arrow' />
                      </label>
                    </div>
                  </div>
                </div>
                {/* <!--product-price-wrapper--> */}
                <div className='product-price-wrapper-v1 product-item-wrapper-v1'>
                  {/* <!--text--> */}
                  <p className='table-text-v1 text-align-center'>
                    ۴,۵۰۰,۰۰۰ تومانء
                  </p>
                </div>
                {/* <!--product-number-wrapper--> */}
                <div className='product-number-wrapper-v1 product-item-wrapper-v1'>
                  {/* <!--product-number--> */}
                  <div className='product-number-box-v1'>
                    {/* <!--add-button--> */}
                    <button className='add-btn-v1 product-number-btn'>+</button>
                    {/* <!--text--> */}
                    <p className='table-text-v1'>۱</p>
                    {/* <!--mines-button--> */}
                    <button className='mines-button-v1 product-number-btn'>
                      -
                    </button>
                  </div>
                </div>
                {/* <!--total-price-wrapper--> */}
                <div className='product-total-price-wrapper-v1 product-item-wrapper-v1'>
                  {/* <!--text--> */}
                  <p className='table-text-v1 text-align-center'>
                    ۴,۵۰۰,۰۰۰ تومانء
                  </p>
                </div>
              </div>
              {/* <!--drop-down--> */}
              <div className='long-term-tr-dorp-down'>
                {/* <!--title--> */}
                <div className='title-td'>
                  {/* <!--title--> */}
                  <h4 className='shop-basket-title-h4'>
                    ویرایش اطلاعات
                    {/* <!--icon--> */}
                    <FaPenSquare className='fa-solid fa-pen-to-square' />
                  </h4>
                </div>
                {/* <!--input-wrapper--> */}
                <div className='input-wrapper-td'>
                  {/* <!--input-group--> */}
                  <div className='table-input-group'>
                    {/* <!--input--> */}
                    <input
                      type='email'
                      name='email'
                      className='table-input'
                      value='iranshopdigi@gmail.com'
                    />
                    {/* <!--text--> */}
                    <div className='table-input-text'>ایمیل:</div>
                  </div>
                  {/* <!--input-group--> */}
                  <div className='table-input-group'>
                    {/* <!--input--> */}
                    <input
                      type='text'
                      name='password'
                      className='table-input'
                      value='iranSHOP'
                    />
                    {/* <!--text--> */}
                    <div className='table-input-text'>رمز :</div>
                  </div>
                  {/* <!--input-group--> */}
                  <div className='table-input-group'>
                    {/* <!--input--> */}
                    <input
                      type='text'
                      className='table-input'
                      value='DASMKMCSC'
                    />
                    {/* <!--text--> */}
                    <div className='table-input-text'>بک آپ کد</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--============Factor-section==========--> */}
        <section className='factor-section'>
          <div className='container'>
            <div className='factor-content-wrapper'>
              <div className='factor-right-side-container'>
                <h3 className='shop-basket-title'>خلاصه فاکتور</h3>
                <div className='facotr-right-side-wrapper factor-wrappers'>
                  <div className='factor-info-box'>
                    <div>
                      {cartData && (
                        <p className='shop-basket-text'>
                          مبلغ فاکتور :{' '}
                          {toPersianNumber(cartData.data.total_amount)} تومان
                        </p>
                      )}
                    </div>
                    <p className='shop-basket-text'>
                      تعداد اقلام: {toPersianNumber(productCardNumber)} عدد
                    </p>
                  </div>
                  <form action='#' className='factor-offer-form'>
                    <p className='shop-basket-text shop-basket-text-offer'>
                      کد تخفیف :
                    </p>
                    <input
                      type='text'
                      className='shop-basket-offer-input'
                      placeholder='کد تخفیف خود را وارد کنید'
                    />
                    <button type='submit' className='offer-submit'>
                      اعمال کد
                    </button>
                  </form>
                  <p className='shop-basket-sub-text price-less-sub-text'>
                    مبلغ کسر شده کد تخفیف : ۱۰ هزار تومانء
                  </p>
                  <div className='charity-box'>
                    <p className='shop-basket-text charity-box-text'>
                      مایلم به خیریه کودکان مبتلا به بیماری کمک کنم
                    </p>
                    <input
                      type='checkbox'
                      className='charity-input'
                      id='charity-check'
                      hidden
                    />
                    <label htmlFor='charity-check' className='charity-label'>
                      <p className='charity-text'>
                        100 تومان
                        <FaChevronDown className='charity-icon' />
                      </p>
                      <ul className='charity-price-list'>
                        <li className='charity-item'>100 تومان</li>
                        <li className='charity-item'>200 تومان</li>
                        <li className='charity-item'>300 تومان</li>
                      </ul>
                    </label>
                  </div>
                  <span className='under-line-v1'></span>
                  <div>
                    {cartData && (
                      <p className='shop-basket-text'>
                        <span className='shop-basket-text-span'>
                          مجموع مبلغ نهایی فاکتور شما :
                        </span>
                        {toPersianNumber(cartData.data.final_amount)} تومان
                      </p>
                    )}
                  </div>
                  <div className='print-factor-box'>
                    <input
                      type='checkbox'
                      className='shop-basket-input'
                      id='print-factor-check'
                      hidden
                    />
                    <label
                      htmlFor='print-factor-check'
                      className='shop-basket-label'
                    >
                      <div className='shop-basket-input-box'>
                        <FaCheck className='shop-basket-check-icon' />
                      </div>
                      درخواست صدور فاکتور دارم
                    </label>
                  </div>
                </div>
              </div>
              <div className='factor-left-side-contaienr'>
                <h3 className='shop-basket-title'>انتخاب روش پرداخت</h3>
                <div className='facotr-left-side-wrapper factor-wrappers'>
                  <div className='bank-boxes-wrapper'>
                    <input
                      type='radio'
                      className='bank-radio-input'
                      id='bank-radio-1'
                      name='bank-radio'
                      hidden
                      defaultChecked
                    />
                    <label htmlFor='bank-radio-1' className='bank-label'>
                      <img src={bank1} alt='img' className='bank-img' />
                      <span className='bank-under-line-span'></span>
                    </label>
                    <input
                      type='radio'
                      className='bank-radio-input'
                      id='bank-radio-2'
                      name='bank-radio'
                      hidden
                    />
                    <label htmlFor='bank-radio-2' className='bank-label'>
                      <img src={bank2} alt='img' className='bank-img' />
                      <span className='bank-under-line-span'></span>
                    </label>
                    <input
                      type='radio'
                      className='bank-radio-input'
                      id='bank-radio-3'
                      name='bank-radio'
                      hidden
                    />
                    <label htmlFor='bank-radio-3' className='bank-label'>
                      <img src={bank3} alt='img' className='bank-img' />
                      <span className='bank-under-line-span'></span>
                    </label>
                  </div>
                  <span className='under-line-v1'></span>
                  <div className='bank-bottom-box'>
                    <div className='bank-license-box'>
                      <input
                        type='checkbox'
                        className='shop-basket-input'
                        id='license-accept-input'
                        hidden
                      />
                      <label
                        htmlFor='license-accept-input'
                        className='shop-basket-label shop-basket-license-label'
                      >
                        <div
                          className='shop-basket-input-box'
                          onClick={() => setIsChecked(!isChecked)}
                        >
                          <FaCheck
                            className='shop-basket-check-icon'
                            style={{ color: isChecked ? 'green' : 'gray' }}
                          />
                        </div>
                        <p className='shop-basket-label-text'>
                          <span className='shop-basket-label-main-text'>
                            شرایط و قوانین وبسایت را مطالعه کردم
                          </span>
                          <span className='shop-basket-label-sub-text'>
                            برای خواندن و اطلاع از قوانین کلیک کنید
                          </span>
                        </p>
                      </label>
                    </div>
                    <a href='#' className='pay-button' onClick={handlePayment}>
                      پرداخت
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <ToastContainer toastClassName='toast-right' />
    </div>
  )
}

export default Basket
