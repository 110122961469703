import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/style.css";
import "../assets/css/fonts.css";
import "../assets/css/media-query.css";
import "../assets/css/variables.css";
import { useNavigate } from "react-router-dom";

import footerlogo from "../assets/images/svg-logo.png";
import footerImg01 from "../assets/images/footer-img-1.png";
import footerImg02 from "../assets/images/footer-img-2.png";
import footerImg03 from "../assets/images/footer-img-3.png";
import logo02 from "../assets/images/logo.png";
import videoBanner from "../assets/images/video-banner.png";
import bagtick from "../assets/images/bag-tick.png";
import comment from "../assets/images/comment-profile-img.png";
import playIcon from "../assets/images/play-background-icon.png";
import tick from "../assets/images/tick-square.png";
import video from "../assets/video/Mountain Golem Awakens ⛰️ Clash of Clans Official.mp4";
import mainimg from "../assets/images/product-page-main-img.jpg";

import vector from "../assets/images/Vector.png";

import logo from "../assets/images/nav-v2-logo.png";

import {
  FaChevronDown,
  FaPercent,
  FaShoppingBag,
  FaPlay,
  FaClock,
  FaMusic,
  FaExclamationTriangle,
  FaPause,
} from "react-icons/fa";

import {
  FaStore,
  FaCaretSquareDown,
  FaChevronCircleRight,
  FaChevronCircleLeft,
  FaPhoneVolume,
  FaAccessibleIcon,
  FaNewspaper,
  FaTh,
  FaCalendarAlt,
  FaComments,
  FaThumbsUp,
  FaShieldAlt,
  FaStar,
  FaTelegram,
  FaWhatsapp,
} from "react-icons/fa";

import { IoMdMenu } from "react-icons/io";
import { FaRegWindowClose } from "react-icons/fa";
import { CiLogin } from "react-icons/ci";
import { RiShoppingBasketFill } from "react-icons/ri";
import { useLocation } from "react-router-dom";

function Product() {
  const navigate = useNavigate();

  // cart info
  const [productCardNumber, setProductCardNumber] = useState(0);

  const [cartData, setCartData] = useState(null);

  useEffect(() => {
    const fetchCartData = async () => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://api.zoodgift.com/api/cart",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
        },
      };

      try {
        const response = await axios.request(config);
        setCartData(response.data);
        setCartCount(response.data.data.products.length);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCartData();
  }, []);

  useEffect(() => {
    const accessToken = sessionStorage.getItem("access_token");
    setIsLoggedIn(!!accessToken);
  }, []);

  const handleProfileClick = (event) => {
    event.preventDefault(); // جلوگیری از رفتار پیش فرض لینک
    const accessToken = sessionStorage.getItem("access_token");
    if (accessToken) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const banners = [
    {
      imgSrc:
        "https://www.yellowjayco.com//uploads/f626b274f226a483d96627c1a36a94e1e1f9fdef.png",
      name: "محمدرضا ",
      phone: "۰۹۱۶۰۲۶۵۶۶۱",
    },
    {
      imgSrc:
        "https://www.yellowjayco.com//uploads/bbde0a0f465d8f99ddfc29d8e08f492323534e1d.png",
      name: "محمدرضا عبادی",
      phone: "۰۹۱۶۰۲۶۵۶۶۱",
    },
  ];

  const handlePrevClick = () => {
    if (!isAnimating) {
      const newIndex =
        currentIndex === 0 ? banners.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
      setIsAnimating(true);
    }
  };

  const handleNextClick = () => {
    if (!isAnimating) {
      const newIndex =
        currentIndex === banners.length - 1 ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
      setIsAnimating(true);
    }
  };

  const handleAnimationEnd = () => {
    setIsAnimating(false);
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const cardId = queryParams.get("id");

  const [productData, setProductData] = useState(null);
  const [relatedProductData, setRelatedProductData] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);

  const [relatedProductData1, setRelatedProductData1] = useState(null);
  const [selectedRelatedCountry1, setSelectedRelatedCountry1] = useState(null);
  const [selectedRelatedPrice1, setSelectedRelatedPrice1] = useState(null);

  const [relatedProductData2, setRelatedProductData2] = useState(null);
  const [selectedRelatedCountry2, setSelectedRelatedCountry2] = useState(null);
  const [selectedRelatedPrice2, setSelectedRelatedPrice2] = useState(null);

  const [bestSellingProductData, setBestSellingProductData] = useState(null);

  const [blogData, setBlogData] = useState(null);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    const fetchRelatedProductData1 = async () => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `https://api.zoodgift.com/api/categories/info/1`,
        headers: {
          Accept: "application/json",
        },
      };

      try {
        const response = await axios.request(config);
        setRelatedProductData1(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchRelatedProductData2 = async () => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `https://api.zoodgift.com/api/categories/info/2`,
        headers: {
          Accept: "application/json",
        },
      };

      try {
        const response = await axios.request(config);
        setRelatedProductData2(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchBestSellingProductData = async () => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `https://api.zoodgift.com/api/categories/info/3`,
        headers: {
          Accept: "application/json",
        },
      };

      try {
        const response = await axios.request(config);
        setBestSellingProductData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchBlogData = async () => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://api.zoodgift.com/api/blog/category/2",
        headers: {},
      };

      try {
        const response = await axios.request(config);
        setBlogData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchRelatedProductData1();
    fetchRelatedProductData2();
    fetchBestSellingProductData();
    fetchBlogData();
  }, []);

  useEffect(() => {
    const fetchProductData = async () => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `https://api.zoodgift.com/api/categories/info/${cardId}`,
        headers: {
          Accept: "application/json",
        },
      };

      try {
        const response = await axios.request(config);
        setProductData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchRelatedProductData = async () => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `https://api.zoodgift.com/api/categories/info/1`, // فرض می‌کنیم همیشه id=1 برای محصولات مرتبط
        headers: {
          Accept: "application/json",
        },
      };

      try {
        const response = await axios.request(config);
        setRelatedProductData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    if (cardId) {
      fetchProductData();
    }
    fetchRelatedProductData();
  }, [cardId]);

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
    setSelectedPrice(null);
  };

  const handlePriceChange = (price) => {
    setSelectedPrice(price);
  };

  useEffect(() => {
    if (selectedPrice) {
      console.log("Selected Product ID:", selectedPrice.product_id);
      console.log("Selected Price:", selectedPrice.price);
    }
  }, [selectedPrice]);

  // add to cart

  const accessToken = sessionStorage.getItem("access_token");
  if (!accessToken) {
    console.error("Access token not found");
    return;
  }

  const handleAddToCart = async () => {
    if (!selectedPrice) return;

    const accessToken = sessionStorage.getItem("access_token");
    if (!accessToken) {
      console.error("Access token not found");
      toast.error("خطا در اضافه کردن محصول");
      return;
    }

    let data = "";

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `https://api.zoodgift.com/api/cart/add/${selectedPrice.product_id}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      if (response.status === 200) {
        console.log(JSON.stringify(response.data));
        setCartCount(cartCount + 1);
        sessionStorage.setItem("cartCount", response.data.data.products.length);
        toast.success("با موفقیت به سبد خرید اضافه شد");
      } else {
        toast.error("خطا در اضافه کردن محصول");
      }
    } catch (error) {
      console.log(error);
      toast.error("خطا در اضافه کردن محصول");
    }
  };

  // handle bascket click

  const handleBasketClick = () => {
    navigate("/card-page");
  };

  if (!productData || !relatedProductData) {
    return <p>Loading...</p>;
  }

  return (
    <div className="body-white-color-2">
      <div className="page-main-container body-white-color-2">
        {/* <!--header-top--> */}
        <div className="header-top-main-wrapper">
          {/* <!--input-for-controlling-the-functionality--> */}
          <input
            type="checkbox"
            className="mobile-nav-input mobile-nav-input-v2"
            id="mobile-nav-check"
            hidden
          />
          <div className="header-top-wrapper">
            {/* <!--setting-contaienr--> */}
            <div className="container">
              {/* <!--navigation--> */}
              <nav className="navigation-v2">
                {/* <!--logo--> */}
                <a href="./index-1.html" className="nav-v2-logo-wrapper">
                  {/* <!--img--> */}
                  <img src={logo} alt="Logo" className="nav-v2-logo" />
                </a>
                {/* <!--list--> */}
                <ul className="nav-v2-list">
                  {/* <!--item--> */}
                  <li className="nav-v2-item">
                    <a href="./product-page.html" className="nav-v2-item-link">
                      لیست گیفت کارت
                    </a>
                  </li>
                  {/* <!--item--> */}
                  <li className="nav-v2-item">
                    <a href="./teach-page.html" className="nav-v2-item-link">
                      آموزش استفاده گیفت کارت
                    </a>
                  </li>
                  {/* <!--item--> */}
                  <li className="nav-v2-item">
                    <a href="./blog.html" className="nav-v2-item-link">
                      بلاگ
                    </a>
                  </li>
                  {/* <!--item--> */}
                  <li className="nav-v2-item">
                    <a href="./error-404.html" className="nav-v2-item-link">
                      تماس با ما
                    </a>
                  </li>
                </ul>
                {/* <!--==mobile-nav==--> */}
                {/* <!--open-icon--> */}
                <label
                  htmlFor="mobile-nav-check"
                  className="mobile-nav-open-label mobile-nav-btns"
                >
                  <IoMdMenu className="fa-solid fa-bars open-nav-icon" />
                </label>

                {/* <!--buttons--> */}
                <div className="nav-v2-links-wrapper">
                  <div className="shop-link-v2-wrapper">
                    {/* <!--number--> */}
                    <span className="shop-link-number">
                      {cartCount.toLocaleString("fa-IR")}
                    </span>
                    {/* <!--link(Shop-link)--> */}
                    <a
                      href="/card-page"
                      className="nav-v2-link nav-v2-shop-link"
                    >
                      {/* <!--icon--> */}
                      <i className="fa-solid fa-basket-shopping"></i>
                      <RiShoppingBasketFill
                        className="fa-solid fa-basket-shopping"
                        onClick={handleBasketClick}
                      />
                    </a>
                  </div>
                  {/* <!--link(login-link)--> */}
                  <a
                    href="/"
                    className="nav-v2-link nav-v2-Login-link"
                    onClick={handleProfileClick}
                  >
                    {isLoggedIn ? "داشبورد" : "ورود به حساب"}
                  </a>
                  {/* <!--login--> */}
                  <a
                    href="./login.html"
                    className="nav-login-btn nav-v2-responsive-login"
                  >
                    <span className="login-text">ورود</span>

                    <CiLogin className="fa-solid fa-arrow-right-to-bracket nav-login-icon" />
                  </a>
                </div>
              </nav>
            </div>
          </div>
          {/* <!--nav-list--> */}
          <ul className="mobile-nav-list">
            {/* <!--close-icon--> */}
            <label
              htmlFor="mobile-nav-check"
              className="mobile-nav-close-label mobile-nav-btns"
            >
              <FaRegWindowClose className="fa-solid fa-x open-close-icon" />
            </label>
            {/* <!--nav-item--> */}
            <li className="mobile-nav-item">
              <a href="./index-2.html" className="mobile-nav-link">
                خانه
              </a>
            </li>
            {/* <!--nav-item--> */}
            <li className="mobile-nav-item">
              <a href="./product-page.html" className="mobile-nav-link">
                گیفت کارت
              </a>
            </li>
            {/* <!--nav-item--> */}
            <li className="mobile-nav-item">
              <a href="./teach-page.html" className="mobile-nav-link">
                آموزش استفاده گیفت کارت
              </a>
            </li>
            {/* <!--nav-item--> */}
            <li className="mobile-nav-item">
              <a href="./error-404.html" className="mobile-nav-link">
                راهنمای خرید گیفت کارت
              </a>
            </li>
            {/* <!--nav-item--> */}
            <li className="mobile-nav-item">
              <a href="./blog.html" className="mobile-nav-link">
                بلاگ
              </a>
            </li>
            {/* <!--nav-item--> */}
            <li className="mobile-nav-item">
              <a href="./error-404.html" className="mobile-nav-link">
                تماس با ما
              </a>
            </li>
          </ul>
        </div>
        {/* <!--==========Main=========--> */}
        <main className="main">
          {/* <!--=========product-content-section===========--> */}
          <section className='product-content-section" id="header-v2'>
            {/* <!--setting-container--> */}
            <div className="container margin-top-big">
              {/* title */}
              <p className="addres-text product-content-addres-text margin-top-super-big">
                خانه/گیفت کارت پلی استیشن
              </p>
              {/* main-wrapper */}
              <div className="product-content-wrapper">
                <div className="product-box-v1 product-wrapper-v1">
                  <div className="product-title-img">
                    {/* title-image */}
                    {/* vector */}
                    <img
                      src={vector}
                      alt="img"
                      className="product-vector-img"
                    />
                    {/* logo */}
                    <img src={logo02} alt="img" className="product-logo-img" />
                  </div>
                  <div className="product-first-row product-box-img-box">
                    {/* img-box */}
                    <h4 className="card-text">
                      {/* caption */}
                      <span className="card-title">گیفت کارت پلی استیشن</span>
                      <span className="card-title-eng">
                        playstation store giftcard
                      </span>
                    </h4>
                    <div className="product-img-wrapper-v2">
                      {/* img-wrapper */}
                      <img src={mainimg} alt="img" className="product-img" />
                    </div>
                  </div>
                  <div className="product-second-row choose-content-wrapper">
                    {/* choose-box */}
                    <div className="choose-region-wrapper choose-wrapper">
                      {/* choose-region-wrapper */}
                      <h5 className="product-choose-title">انتخاب ریجن</h5>
                      {/* title */}
                      <div className="choose-options-wrapper">
                        {/* wrapper */}
                        <input
                          type="radio"
                          name="region-radio"
                          className="choose-input"
                          id="choose-1"
                          defaultChecked
                          hidden
                        />
                        {/* input */}
                        <label htmlFor="choose-1" className="choose-label">
                          آمریکا
                        </label>
                        {/* label */}
                        <input
                          type="radio"
                          name="region-radio"
                          className="choose-input"
                          id="choose-2"
                          hidden
                        />
                        {/* input */}
                        <label htmlFor="choose-2" className="choose-label">
                          فرانسه
                        </label>
                        {/* label */}
                        <input
                          type="radio"
                          name="region-radio"
                          className="choose-input"
                          id="choose-3"
                          hidden
                        />
                        {/* input */}
                        <label htmlFor="choose-3" className="choose-label">
                          انگلیس
                        </label>
                        {/* label */}
                      </div>
                    </div>
                    <div className="choose-price-wrapper choose-wrapper">
                      {/* choose-Price-wrapper */}
                      <h5 className="product-choose-title">انتخاب مقدار:</h5>
                      {/* title */}
                      <input
                        type="checkbox"
                        className="product-price-input-v1"
                        id="product-price-input-id"
                        hidden
                      />
                      {/* input-for-controlling-the-functionality */}
                      <label
                        htmlFor="product-price-input-id"
                        className="product-pric-label"
                      >
                        <p className="product-price-drop-text">
                          ۱ دلار
                          {/* icon */}
                          <i className="fa-solid fa-chevron-down product-chevron-down-icon"></i>
                        </p>
                        <ul className="product-price-drop-list">
                          {/* list */}
                          <li className="product-price-drop-item">۱ دلار</li>
                          {/* item */}
                          <li className="product-price-drop-item">۲ دلار</li>
                          {/* item */}
                          <li className="product-price-drop-item">۳ دلار</li>
                          {/* item */}
                          <li className="product-price-drop-item">۴ دلار</li>
                          {/* item */}
                          <li className="product-price-drop-item">۵ دلار</li>
                          {/* item */}
                          <li className="product-price-drop-item">۱۰ دلار</li>
                          {/* item */}
                          <li className="product-price-drop-item">۲۰ دلار</li>
                          {/* item */}
                          <li className="product-price-drop-item">۳۰ دلار</li>
                          {/* item */}
                          <li className="product-price-drop-item">۴۰ دلار</li>
                          {/* item */}
                          <li className="product-price-drop-item">۵۰ دلار</li>
                          {/* item */}
                          <li className="product-price-drop-item">۷۵ دلار</li>
                          {/* item */}
                          <li className="product-price-drop-item">۱۰۰ دلار</li>
                          {/* item */}
                        </ul>
                      </label>
                    </div>
                  </div>
                  <div className="product-third-row product-pay-box">
                    {/* product-pay-box */}
                    <div className="offer-text-box-wrapper">
                      {/* off-box */}
                      <p className="offer-main-text-v1">
                        {/* text */}
                        ۳۵۰,۰۰۰
                      </p>
                      <p className="offer-text-v1">
                        {/* text */}
                        <span className="offer-percent-box">
                          {/* percent-box */}
                          <i className="fa-solid fa-percent"></i>
                          {/* icon */}
                        </span>
                        <span className="offer-number-v1">
                          {/* offer-number */}
                          10
                        </span>
                      </p>
                    </div>
                    <p className="product-total-price-text">
                      {/* text */}
                      <span className="total-price-main-text">
                        مجموع فاکتور شما :
                      </span>
                      <span className="total-price-sub-text">
                        ۲۵۰,۰۰۰
                        <span className="total-price-main-text">تومان</span>
                      </span>
                    </p>
                    <button className="factor-btn btn-secondary-v1">
                      افزودن به سبد خرید
                      {/* button */}
                      <i className="fa-solid fa-bag-shopping factor-btn-icon btn-secondary-v1-icon"></i>
                      {/* icon */}
                    </button>
                    <div className="more-option-link-wrapper">
                      {/* more-option-box */}
                      <a href="#" className="more-opiton-link">
                        {/* link */}
                        <i className="fa-solid fa-play more-option-link-icon"></i>
                        {/* icon */}
                        راهنمای خرید محصول
                      </a>
                      <a href="#" className="more-opiton-link">
                        {/* link */}
                        <i className="fa-regular fa-clock more-option-link-icon"></i>
                        {/* icon */}
                        تحویل محصول فوری
                      </a>
                    </div>
                    <div className="warning-box">
                      {/* warning */}
                      <h4 className="warning-box-title">
                        {/* title */}
                        <i className="fa-solid fa-triangle-exclamation warning-box-icon"></i>
                        {/* icon */}
                        لطفا در انتخاب محصول دقت فرمایید امکان برگشت محصول وجود
                        ندارد
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!--=========Product-main-content-wrapper==========--> */}
          <section className="product-main-content-section">
            {/* <!--setting-container--> */}
            <div className="container">
              <h4 className="h4-title product-title">
                <FaStore className="product-title-text" />
                محصولات مرتبط
              </h4>
              <div className="product-main-content-wrapper">
                <div className="product-main-content-right-side">
                  <section className="same-product-section-v1">
                    {relatedProductData1 && (
                      <>
                        <input
                          type="checkbox"
                          className="same-product-input"
                          id="same-product-check-1"
                          hidden
                        />
                        <div className="same-product-box-v1 product-box-style">
                          <div className="same-product-box-v1-top">
                            <input
                              type="checkbox"
                              className="same-product-buy-input"
                              id="same-product-buy-check"
                              hidden
                            />
                            <div className="same-product-box-v1-top-right">
                              <div className="product-shop-box-v1">
                                <img
                                  src={bagtick}
                                  alt="img"
                                  className="product-shop-box-v1-icon"
                                />
                              </div>
                              <div className="product-img-and-title-wrapper">
                                <img
                                  src={relatedProductData1.image}
                                  alt="img"
                                  className="same-product-img-v1"
                                />
                                <div className="same-product-top-text-box">
                                  <h4 className="same-product-title">
                                    {relatedProductData1.title}
                                  </h4>
                                  <label
                                    htmlFor="same-product-check-1"
                                    className="same-product-see-more-deatails-label"
                                  >
                                    مشاهده جزئیات
                                    <FaCaretSquareDown />
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="same-product-box-v1-left-wrapper">
                              <p className="same-product-price">
                                <span className="same-product-price-number">
                                  {selectedRelatedPrice1
                                    ? selectedRelatedPrice1.price.toLocaleString()
                                    : "۰"}
                                </span>
                                قیمت
                              </p>
                              <label
                                htmlFor="same-product-buy-check"
                                className="same-product-add-to-cart-btn"
                              >
                                افزودن به سبد خرید
                              </label>
                            </div>
                          </div>
                          <div className="same-product-box-v1-bottom">
                            <span className="same-product-line"></span>
                            <div className="same-product-option-wrapper">
                              <div className="choose-region-wrapper choose-wrapper">
                                <h5 className="product-choose-title">
                                  انتخاب ریجن
                                </h5>
                                <div className="choose-options-wrapper same-product-choose-option-wrapper">
                                  {relatedProductData1.countires.map(
                                    (country, index) => (
                                      <React.Fragment key={country.id}>
                                        <input
                                          type="radio"
                                          name="region-radio-same-product"
                                          className="choose-input"
                                          id={`same-product-choose-${index}`}
                                          hidden
                                          onChange={() => {
                                            setSelectedRelatedCountry1(country);
                                            setSelectedRelatedPrice1(null);
                                          }}
                                        />
                                        <label
                                          htmlFor={`same-product-choose-${index}`}
                                          className="choose-label same-product-choose-lable"
                                        >
                                          {country.title}
                                        </label>
                                      </React.Fragment>
                                    )
                                  )}
                                </div>
                              </div>
                              {selectedRelatedCountry1 && (
                                <div className="length-time-wrapper">
                                  <h5 className="product-choose-title">
                                    انتخاب مقدار
                                  </h5>
                                  <input
                                    type="checkbox"
                                    className="charity-input"
                                    id="charity-check"
                                    hidden
                                  />
                                  <label
                                    htmlFor="charity-check"
                                    className="charity-label product-page-drop-down"
                                  >
                                    <p className="charity-text">
                                      {selectedRelatedPrice1
                                        ? `${selectedRelatedPrice1.balance} ${
                                            selectedRelatedCountry1.title ===
                                            "آمریکا"
                                              ? "دلار"
                                              : selectedRelatedCountry1.title ===
                                                "آلمان"
                                              ? "یورو"
                                              : "پوند"
                                          }`
                                        : "انتخاب مقدار"}
                                      <FaCaretSquareDown className="charity-icon" />
                                    </p>
                                    <ul className="charity-price-list">
                                      {selectedRelatedCountry1.giftCardRows.map(
                                        (gift) => (
                                          <li
                                            key={gift.id}
                                            className="charity-item"
                                            onClick={() =>
                                              setSelectedRelatedPrice1(gift)
                                            }
                                          >
                                            {`${gift.balance} ${
                                              selectedRelatedCountry1.title ===
                                              "آمریکا"
                                                ? "دلار"
                                                : selectedRelatedCountry1.title ===
                                                  "آلمان"
                                                ? "یورو"
                                                : "پوند"
                                            }`}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </label>
                                </div>
                              )}
                              {selectedRelatedPrice1 && (
                                <div>
                                  <input
                                    type="hidden"
                                    name="relatedgiftselected_id"
                                    value={selectedRelatedPrice1.id}
                                  />
                                  <input
                                    type="hidden"
                                    name="relatedgiftselected_product_id"
                                    value={selectedRelatedPrice1.product_id}
                                  />
                                </div>
                              )}
                            </div>
                            <span className="same-product-line"></span>
                            <p className="same-product-paragraph">
                              لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                              صنعت چاپ، و با استفاده از طراحان گرافیک است،
                              چاپگرها و متون بلکه روزنامه و مجله در ستون و
                              سطرآنچنان که لازم است،
                              <span className="same-product-see-more">
                                توضیح بیشتر
                                <span className="same-product-dots">...</span>
                              </span>
                            </p>
                          </div>
                        </div>
                      </>
                    )}

                    {relatedProductData2 && (
                      <>
                        <input
                          type="checkbox"
                          className="same-product-input"
                          id="same-product-check-2"
                          hidden
                        />
                        <div className="same-product-box-v1 product-box-style">
                          <div className="same-product-box-v1-top">
                            <input
                              type="checkbox"
                              className="same-product-buy-input"
                              id="same-product-buy-check-2"
                              hidden
                            />
                            <div className="same-product-box-v1-top-right">
                              <div className="product-shop-box-v1">
                                <img
                                  src={bagtick}
                                  alt="img"
                                  className="product-shop-box-v1-icon"
                                />
                              </div>
                              <div className="product-img-and-title-wrapper">
                                <img
                                  src={relatedProductData2.image}
                                  alt="img"
                                  className="same-product-img-v1"
                                />
                                <div className="same-product-top-text-box">
                                  <h4 className="same-product-title">
                                    {relatedProductData2.title}
                                  </h4>
                                  <label
                                    htmlFor="same-product-check-2"
                                    className="same-product-see-more-deatails-label"
                                  >
                                    مشاهده جزئیات
                                    <FaCaretSquareDown />
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="same-product-box-v1-left-wrapper">
                              <p className="same-product-price">
                                <span className="same-product-price-number">
                                  {selectedRelatedPrice2
                                    ? selectedRelatedPrice2.price.toLocaleString()
                                    : "۰"}
                                </span>
                                قیمت
                              </p>
                              <label
                                htmlFor="same-product-buy-check-2"
                                className="same-product-add-to-cart-btn"
                              >
                                افزودن به سبد خرید
                              </label>
                            </div>
                          </div>
                          <div className="same-product-box-v1-bottom">
                            <span className="same-product-line"></span>
                            <div className="same-product-option-wrapper">
                              <div className="choose-region-wrapper choose-wrapper">
                                <h5 className="product-choose-title">
                                  انتخاب ریجن
                                </h5>
                                <div className="choose-options-wrapper same-product-choose-option-wrapper">
                                  {relatedProductData2.countires.map(
                                    (country, index) => (
                                      <React.Fragment key={country.id}>
                                        <input
                                          type="radio"
                                          name="region-radio-same-product-v4"
                                          className="choose-input"
                                          id={`same-product-choose2-${index}`}
                                          hidden
                                          onChange={() => {
                                            setSelectedRelatedCountry2(country);
                                            setSelectedRelatedPrice2(null);
                                          }}
                                        />
                                        <label
                                          htmlFor={`same-product-choose2-${index}`}
                                          className="choose-label same-product-choose-lable"
                                        >
                                          {country.title}
                                        </label>
                                      </React.Fragment>
                                    )
                                  )}
                                </div>
                              </div>
                              {selectedRelatedCountry2 && (
                                <div className="length-time-wrapper">
                                  <h5 className="product-choose-title">
                                    انتخاب مقدار
                                  </h5>
                                  <input
                                    type="checkbox"
                                    className="charity-input"
                                    id="charity-check-2"
                                    hidden
                                  />
                                  <label
                                    htmlFor="charity-check-2"
                                    className="charity-label product-page-drop-down"
                                  >
                                    <p className="charity-text">
                                      {selectedRelatedPrice2
                                        ? `${selectedRelatedPrice2.balance} ${
                                            selectedRelatedCountry2.title ===
                                            "آمریکا"
                                              ? "دلار"
                                              : selectedRelatedCountry2.title ===
                                                "آلمان"
                                              ? "یورو"
                                              : "پوند"
                                          }`
                                        : "انتخاب مقدار"}
                                      <FaCaretSquareDown className="charity-icon" />
                                    </p>
                                    <ul className="charity-price-list">
                                      {selectedRelatedCountry2.giftCardRows.map(
                                        (gift) => (
                                          <li
                                            key={gift.id}
                                            className="charity-item"
                                            onClick={() =>
                                              setSelectedRelatedPrice2(gift)
                                            }
                                          >
                                            {`${gift.balance} ${
                                              selectedRelatedCountry2.title ===
                                              "آمریکا"
                                                ? "دلار"
                                                : selectedRelatedCountry2.title ===
                                                  "آلمان"
                                                ? "یورو"
                                                : "پوند"
                                            }`}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </label>
                                </div>
                              )}
                              {selectedRelatedPrice2 && (
                                <div>
                                  <input
                                    type="hidden"
                                    name="relatedgiftselected_id"
                                    value={selectedRelatedPrice2.id}
                                  />
                                  <input
                                    type="hidden"
                                    name="relatedgiftselected_product_id"
                                    value={selectedRelatedPrice2.product_id}
                                  />
                                </div>
                              )}
                            </div>
                            <span className="same-product-line"></span>
                            <p className="same-product-paragraph">
                              لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                              صنعت چاپ، و با استفاده از طراحان گرافیک است،
                              چاپگرها و متون بلکه روزنامه و مجله در ستون و
                              سطرآنچنان که لازم است،
                              <span className="same-product-see-more">
                                توضیح بیشتر
                                <span className="same-product-dots">...</span>
                              </span>
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </section>
                  <div className="product-blog-content product-box-style">
                    <section className="product-video-section ">
                      <h4 className="h4-title product-title">
                        گیفت کارت پلی استیشن چیست ؟
                      </h4>
                      <p className="product-sub-title-text">
                        پرداخت و خرید آسان از پلی استیشن با گیفت کارت پلی استیشن
                      </p>
                      <div className="video-wrapper">
                        <input
                          type="checkbox"
                          className="video-input"
                          id="video-click"
                          hidden
                        />
                        <label htmlFor="video-click" className="video-banner">
                          <img
                            src={videoBanner}
                            alt="img"
                            className="video-banner-img"
                          />
                          <img
                            src={playIcon}
                            className="video-banner-play-img"
                            alt="img"
                          />
                          <div className="video-banner-text-box">
                            <p className="video-banner-text">
                              آموزش شارژ حساب کلش اف کلنز
                            </p>
                            <p className="video-banner-text">
                              مدت زمان ویدیو : 38 ثانیه
                            </p>
                          </div>
                        </label>
                        <video
                          src={video}
                          className="video-wrapper-video"
                          controls
                        ></video>
                      </div>
                    </section>
                    <section className="product-blog-info-section">
                      <h4 className="h4-title">
                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                      </h4>
                      <p className="paragraph">
                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت
                        چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون
                        بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و
                        برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با
                        هدف بهبود ابزارهای کاربردیلورم ایپسوم متن ساختگی با
                        تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان
                        گرافیک است
                      </p>
                      <div className="blog-info-banner">
                        <img
                          src="./assets/images/product-blog-banner.jpg"
                          alt="img"
                          className="blog-info-img"
                        />
                      </div>
                    </section>
                    <section className="blog-podcast-section product-blog-podcast-section">
                      <div className="podcast-box">
                        <div className="podcast-text-box">
                          <h5 className="podcast-title">
                            <FaMusic className="podcast-icon" />
                            نکاتی که قبل از خرید گیفت کارت پلی استیشن باید
                            بدانید
                          </h5>
                          <div className="podcast-text">
                            زود گیفت مرجع خرید انواع گیفت کارت
                          </div>
                        </div>
                        <div className="podcast-track-wrapper">
                          <input
                            type="checkbox"
                            id="podcast-checkbox"
                            className="podcast-input"
                            hidden
                          />
                          <label
                            htmlFor="podcast-checkbox"
                            className="podcast-box-label podcast-play-btn"
                          >
                            <FaPlay className="podcast-play-icon podcast-btn-icon" />
                            <FaPause className="podcast-pause-icon podcast-btn-icon" />
                          </label>
                          <div className="music-loader-box">
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                            <span className="stroke"></span>
                          </div>
                        </div>
                      </div>
                      <h4 className="h4-title">
                        آموزش خرید گیفت کارت پلی استیشن از طریق سایت زود گیفت
                        ...
                      </h4>
                      <div className="table-wrapper">
                        <div className="table-header-wrapper">
                          <p className="table-text table-text-white">
                            سرعت گوشی در اجرای کلش اف کلنز
                          </p>
                          <p className="table-text table-text-white">
                            جی ال ایکس{" "}
                          </p>
                          <p className="table-text table-text-white">آیفون</p>
                        </div>
                        <div className="table-body-wrapper">
                          <p className="table-text table-text-black">
                            قدرت اجرای بازی قوی{" "}
                          </p>
                          <p className="table-text table-text-black">ندارد</p>
                          <p className="table-text table-text-black">دارد</p>
                        </div>
                        <div className="table-body-wrapper">
                          <p className="table-text table-text-black">
                            قدرت اجرای بازی قوی{" "}
                          </p>
                          <p className="table-text table-text-black">ندارد</p>
                          <p className="table-text table-text-black">دارد</p>
                        </div>
                        <div className="table-body-wrapper">
                          <p className="table-text table-text-black">
                            قدرت اجرای بازی قوی{" "}
                          </p>
                          <p className="table-text table-text-black">ندارد</p>
                          <p className="table-text table-text-black">دارد</p>
                        </div>
                      </div>
                      <h4 className="h4-title product-podcast-title">
                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                      </h4>
                      <ul className="podcast-list product-podcast-list">
                        <li className="podcast-item">
                          <img
                            src={tick}
                            alt="img"
                            className="podcast-item-icon"
                          />
                          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                        </li>
                        <li className="podcast-item">
                          <img
                            src={tick}
                            alt="img"
                            className="podcast-item-icon"
                          />
                          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                        </li>
                        <li className="podcast-item">
                          <img
                            src={tick}
                            alt="img"
                            className="podcast-item-icon"
                          />
                          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم
                        </li>
                      </ul>
                    </section>
                    <section className="product-your-question-section">
                      <div className="line-title-wrapper">
                        <h4 className="line-title-text">
                          <FaAccessibleIcon />
                          سوالات متدوال
                        </h4>
                        <span className="line-title-line"></span>
                      </div>
                      <input
                        type="checkbox"
                        className="question-input"
                        id="question-input-1"
                        hidden
                      />
                      <label
                        htmlFor="question-input-1"
                        className="question-label question-label-v2"
                      >
                        <h5 className="question-text">
                          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت
                          چاپ؟
                          <FaCaretSquareDown className="question-icon question-icon-v2" />
                        </h5>
                        <p className="question-answer">
                          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت
                          چاپ، و با استفاده از طراحان گرافیک است،
                        </p>
                      </label>
                      <input
                        type="checkbox"
                        className="question-input"
                        id="question-input-2"
                        hidden
                      />
                      <label
                        htmlFor="question-input-2"
                        className="question-label question-label-v2"
                      >
                        <h5 className="question-text">
                          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت
                          چاپ؟
                          <FaCaretSquareDown className="question-icon question-icon-v2" />
                        </h5>
                        <p className="question-answer">
                          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت
                          چاپ، و با استفاده از طراحان گرافیک است،
                        </p>
                      </label>
                    </section>
                  </div>
                </div>
                <div className="product-main-content-left-side">
                  <div className="slider-x slider-width-100">
                    <div
                      className="expert-banner"
                      onAnimationEnd={handleAnimationEnd}
                      key={currentIndex}
                    >
                      <div className="expert-banner-btns">
                        <button
                          className="expert-banner-btn"
                          onClick={handlePrevClick}
                        >
                          <FaChevronCircleLeft className="expert-banner-btn-icon" />
                        </button>
                        <button
                          className="expert-banner-btn"
                          onClick={handleNextClick}
                        >
                          <FaChevronCircleRight className="expert-banner-btn-icon" />
                        </button>
                      </div>
                      <img
                        src={banners[currentIndex].imgSrc}
                        alt="img"
                        className="expert-banner-img"
                      />
                      <h5 className="expert-title">
                        <span className="expert-sub-title">کارشناسان فروش</span>
                        <span className="expert-main-title">
                          {banners[currentIndex].name}
                        </span>
                      </h5>
                      <p className="expert-number-text">
                        {banners[currentIndex].phone}
                      </p>
                      <div className="social-links-wrapper">
                        <a href="#" className="social-link">
                          <FaPhoneVolume />
                        </a>
                        <a href="#" className="social-link">
                          <FaTelegram />
                        </a>
                        <a href="#" className="social-link">
                          <FaWhatsapp />
                        </a>
                      </div>
                    </div>
                  </div>
                  <section className="product-section-v2 product-box-style">
                    <h5 className="product-title-v2">
                      {bestSellingProductData.title}
                    </h5>
                    <img
                      src={bestSellingProductData.image}
                      alt="img"
                      className="product-img-v2"
                    />
                    <div className="choose-region-wrapper choose-wrapper">
                      <h5 className="product-choose-title">انتخاب ریجن:</h5>
                      <div className="choose-options-wrapper same-product-choose-option-wrapper">
                        {bestSellingProductData.countires.map(
                          (country, index) => (
                            <React.Fragment key={country.id}>
                              <input
                                type="radio"
                                name="region-radio-same-product-v3"
                                className="choose-input"
                                id={`choose-7-${index}`}
                                hidden
                                onChange={() => handleCountryChange(country)}
                              />
                              <label
                                htmlFor={`choose-7-${index}`}
                                className="choose-label same-product-choose-lable"
                              >
                                {country.title}
                              </label>
                            </React.Fragment>
                          )
                        )}
                      </div>
                    </div>
                    {selectedCountry && (
                      <div className="choose-price-wrapper-v2">
                        <h5 className="product-choose-title">انتخاب مقدار:</h5>
                        <input
                          type="checkbox"
                          className="product-price-input-v2"
                          id="product-price-check-v2"
                          hidden
                        />
                        <label
                          htmlFor="product-price-check-v2"
                          className="product-price-label-v2"
                        >
                          <p className="product-price-label-text">
                            {selectedPrice
                              ? `${selectedPrice.balance} ${
                                  selectedCountry.title === "آمریکا"
                                    ? "دلار"
                                    : selectedCountry.title === "آلمان"
                                    ? "یورو"
                                    : "پوند"
                                }`
                              : "انتخاب مقدار"}
                            <FaCaretSquareDown className="product-price-label-icon" />
                          </p>
                          <ul className="product-price-drop-list">
                            {selectedCountry.giftCardRows.map((gift) => (
                              <li
                                key={gift.id}
                                className="product-price-drop-item"
                                onClick={() => handlePriceChange(gift)}
                              >
                                {`${gift.balance} ${
                                  selectedCountry.title === "آمریکا"
                                    ? "دلار"
                                    : selectedCountry.title === "آلمان"
                                    ? "یورو"
                                    : "پوند"
                                }`}
                              </li>
                            ))}
                          </ul>
                        </label>
                        <p className="product-total-price-text">
                          <span className="total-price-main-text">
                            مجموع فاکتور شما :
                          </span>
                          <span className="total-price-sub-text">
                            {selectedPrice
                              ? selectedPrice.price.toLocaleString()
                              : "۰"}
                            <span className="total-price-main-text">تومان</span>
                          </span>
                        </p>
                        <button className="factor-btn btn-secondary-v1">
                          افزودن به سبد خرید
                          <FaShoppingBag className="factor-btn-icon btn-secondary-v1-icon" />
                        </button>
                      </div>
                    )}
                  </section>
                  <div className="more-option-link-wrapper">
                    <a href="#" className="more-opiton-link">
                      <FaPlay className="more-option-link-icon" />
                      راهنمای خرید محصول
                    </a>
                    <a href="#" className="more-opiton-link">
                      <FaClock className="more-option-link-icon" />
                      تحویل محصول فوری
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!--==========same-articles-section=========--> */}
          <section className="same-articles-section product-same-article-section">
            <div className="container">
              <h4 className="h4-title">
                <FaNewspaper />
                مقالات مرتبط
              </h4>
              <div className="product-same-article-slider slider-x slider-width-100 slider-hight-big">
                {blogData.posts.map((post) => (
                  <a href="./blog.html" className="blog-box-link" key={post.id}>
                    <div className="blog-box product-blog-box">
                      <img
                        src={post.image || "default-image-url"} // در صورت نبودن تصویر، یک تصویر پیش فرض قرار می‌دهیم
                        alt="blog-img"
                        className="blog-img product-blog-img"
                      />
                      <h5 className="h5-title prodcut-blog-title">
                        {post.title}
                      </h5>
                      <div className="blog-box-down-wrapper">
                        <p className="blog-text">
                          <FaTh />
                          {blogData.title}{" "}
                          {/* عنوان دسته‌بندی را نمایش می‌دهد */}
                        </p>
                        <p className="blog-text">
                          <FaCalendarAlt />
                          {post.created_at || "تاریخ نامشخص"}{" "}
                          {/* تاریخ ایجاد مطلب را نمایش می‌دهد */}
                        </p>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </section>
          {/* <!--=======comment-section==========--> */}
          <section className="comment-section product-comment-section">
            <div className="smaller-container">
              <div className="comment-section-wrapper">
                <h5 className="comment-section-title">
                  <FaComments className="comment-section-title-icon" />
                  دیدگاه کاربران
                </h5>
                <div className="comment-box">
                  <div className="comment-box-content-wrapper">
                    <div className="comment-profile-img">
                      <img
                        src={comment}
                        alt="img"
                        className="comment-profile-img-content"
                      />
                    </div>
                    <div className="comment-content">
                      <div className="comment-content-top">
                        <p className="comment-owner-name">
                          اصغر جعفری | کاربر مهمان
                        </p>
                        <div className="comment-top-left-wrapper">
                          <button className="comment-opinion-button">
                            <FaThumbsUp className="comment-opinion-icon like-icon" />
                            پسندیدم (۵)
                          </button>
                          <button className="comment-opinion-button">
                            <FaThumbsUp className="comment-opinion-icon deslike-icon" />
                            پسندیدم (۵)
                          </button>
                        </div>
                      </div>
                      <p className="comment-text">
                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت
                        چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون
                        بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است
                      </p>
                      <div className="comment-response-box">
                        <div className="comment-profile-img-response">
                          <img
                            src={comment}
                            alt="img"
                            className="comment-profile-img-content"
                          />
                        </div>
                        <div className="comment-content comment-content-response">
                          <div className="comment-content-top comment-content-top-responsive">
                            <p className="comment-owner-name comment-owner-response">
                              اصغر جعفری | کاربر مهمان
                            </p>
                            <div className="comment-top-left-wrapper">
                              <button className="comment-opinion-button comment-opinion-button-response">
                                <FaThumbsUp className="comment-opinion-icon like-icon" />
                                پسندیدم (۵)
                              </button>
                              <button className="comment-opinion-button comment-opinion-button-response">
                                <FaThumbsUp className="comment-opinion-icon deslike-icon" />
                                پسندیدم (۵)
                              </button>
                            </div>
                          </div>
                          <p className="comment-text comment-text-response">
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                            صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و
                            متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم
                            است
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="comment-box">
                  <div className="comment-box-content-wrapper">
                    <div className="comment-profile-img">
                      <img
                        src={comment}
                        alt="img"
                        className="comment-profile-img-content"
                      />
                    </div>
                    <div className="comment-content">
                      <div className="comment-content-top">
                        <p className="comment-owner-name">
                          اصغر جعفری | کاربر مهمان
                        </p>
                        <div className="comment-top-left-wrapper">
                          <button className="comment-opinion-button">
                            <FaThumbsUp className="comment-opinion-icon like-icon" />
                            پسندیدم (۵)
                          </button>
                          <button className="comment-opinion-button">
                            <FaThumbsUp className="comment-opinion-icon deslike-icon" />
                            پسندیدم (۵)
                          </button>
                        </div>
                      </div>
                      <p className="comment-text">
                        لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت
                        چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون
                        بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است
                      </p>
                      <div className="comment-response-box">
                        <div className="comment-profile-img-response">
                          <img
                            src={comment}
                            alt="img"
                            className="comment-profile-img-content"
                          />
                        </div>
                        <div className="comment-content comment-content-response">
                          <div className="comment-content-top comment-content-top-responsive">
                            <p className="comment-owner-name comment-owner-response">
                              اصغر جعفری | کاربر مهمان
                            </p>
                            <div className="comment-top-left-wrapper">
                              <button className="comment-opinion-button comment-opinion-button-response">
                                <FaThumbsUp className="comment-opinion-icon like-icon" />
                                پسندیدم (۵)
                              </button>
                              <button className="comment-opinion-button comment-opinion-button-response">
                                <FaThumbsUp className="comment-opinion-icon deslike-icon" />
                                پسندیدم (۵)
                              </button>
                            </div>
                          </div>
                          <p className="comment-text comment-text-response">
                            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                            صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و
                            متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم
                            است
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="send-comment-wrapper">
                  <div className="send-comment-top">
                    <h5 className="comment-section-title margin-bottom-0">
                      <FaComments className="comment-section-title-icon" />
                      دیدگاه کاربران
                    </h5>
                    <p className="send-comment-text">
                      <FaShieldAlt className="send-comment-icon" />
                      پس از تایید ادمین نظر شما ارسال می شود
                    </p>
                  </div>
                  <form action="./error-404.html" className="comment-send-form">
                    <textarea
                      name="comment-send-text-area"
                      placeholder="نظر خود را وارد کنید"
                      className="comment-send-text-area"
                    ></textarea>
                    <div className="form-inputs-wrapper">
                      <input
                        type="text"
                        name="name"
                        placeholder="نام و نام خانوادگی"
                        className="form-input form-name-input"
                        required
                      />
                      <input
                        type="email"
                        name="email"
                        placeholder="ایمیل خود را وارد کنید"
                        className="form-input form-email-input"
                        required
                      />
                      <div className="stars-wrapper">
                        <FaStar className="stars-icon" />
                        <FaStar className="stars-icon" />
                        <FaStar className="stars-icon" />
                        <FaStar className="stars-icon" />
                        <FaStar className="stars-icon" />
                      </div>
                      <button type="submit" className="form-send-button">
                        ارسال دیدگاه
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* <!--=========Footer==============--> */}
        <footer className="footer-v2 ">
          {/* <!--logo--> */}
          <img src={footerlogo} alt="logo" className="footer-logo" />
          {/* <!--===setting-container===--> */}
          <div className="bigger-container">
            {/* <!--footer-content-wrapper--> */}
            <div className="footer-content-wrapper-v2">
              {/* <!--footer-top-wrapper--> */}
              <div className="footer-top-wrapper-v2">
                {/* <!--right-side--> */}
                <div className="footer-top-right-wrapper-v2">
                  {/* <!--title--> */}
                  <h5 className="footer-row-title footer-row-title-v2">
                    خدمات ما
                  </h5>
                  <div className="footer-list-wrapper-v2">
                    {/* <!--list--> */}
                    <ul className="footer-list footer-list-v2">
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          خرید گیفت کارت اپل
                        </a>
                      </li>
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          خرید گیفت کارت پلی استیشن
                        </a>
                      </li>
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          خرید گیفت کارت استیم{" "}
                        </a>
                      </li>
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          خرید گیفت کارت ایکس باکس
                        </a>
                      </li>
                    </ul>
                    {/* <!--list--> */}
                    <ul className="footer-list footer-list-v2">
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          خرید گیفت کارت گوگل پلی
                        </a>
                      </li>
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          خرید گیفت کارت پلی استیشن پلاس
                        </a>
                      </li>
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          خرید یوسی پابجی{" "}
                        </a>
                      </li>
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          خرید جم کلش اف کلنز
                        </a>
                      </li>
                    </ul>
                    {/* <!--list--> */}
                    <ul className="footer-list footer-list-v2">
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          خرید اشتراک پریمیوم تلگرام
                        </a>
                      </li>
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          خرید اشتراک اسپاتیفای
                        </a>
                      </li>
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          خرید لایسنس ادوبی{" "}
                        </a>
                      </li>
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          خرید ویباکس فورتنایت
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="footer-top-left-wrapper-v2">
                  {/* <!--title--> */}
                  <h5 className="footer-row-title footer-row-title-v2">
                    دسترسی سریع
                  </h5>
                  <div className="footer-list-wrapper-v2">
                    {/* <!--list--> */}
                    <ul className="footer-list footer-list-v2">
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./blog.html" className="footer-item-link">
                          بلاگ زود گیفت{" "}
                        </a>
                      </li>
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          شرایط و قوانین خرید{" "}
                        </a>
                      </li>
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          حساب کاربری
                        </a>
                      </li>
                      {/* <!--item--> */}
                      <li className="footer-item">
                        <a href="./error-404.html" className="footer-item-link">
                          تماس با ما
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <!--footer-middle-wrapper--> */}
              <div className="footer-middle-wrapper-v2">
                {/* <!--phone-number-box--> */}
                <div className="footer-phone-number-box">
                  {/* <!--title--> */}
                  <h5 className="footer-row-title">اطلاعات تماس</h5>
                  {/* <!--footer-middle-text--> */}
                  <p className="footer-middle-text">
                    <a href="./error-404.html" className="middle-text-link">
                      ۰۶۱۵۲۳۳۳۷۹۴
                    </a>{" "}
                    |{" "}
                    <a href="./error-404.html" className="middle-text-link">
                      ۰۹۱۶۰۲۶۵۶۶۱
                    </a>
                  </p>
                </div>
                {/* <!--socila-box--> */}
                <div className="footer-social-box">
                  {/* <!--title--> */}
                  <h5 className="footer-row-title">زود گیفت در شبکه اجتماعی</h5>
                  {/* <!--footer-middle-text--> */}
                  <p className="footer-middle-text-social">
                    <a href="./error-404.html" className="middle-text-link">
                      تلگرام
                    </a>
                    <a href="./error-404.html" className="middle-text-link">
                      اینستاگرام
                    </a>
                    <a href="./error-404.html" className="middle-text-link">
                      یوتیوب
                    </a>
                  </p>
                </div>
              </div>
              {/* <!--footer-bottom-wrapper--> */}
              <div className="footer-bottom-wrapper">
                {/* <!--footer-row--> */}
                <div className="footer-row footer-big-row-v2">
                  {/* <!--title--> */}
                  <h5 className="footer-row-title-v2 footer-row-title">
                    تجربه‌ای متفاوت در خرید گیفت کارت
                  </h5>
                  {/* <!--text--> */}
                  <p className="footer-text footer-text-v2">
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و
                    با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی
                    تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای
                    کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و
                    آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم
                    افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص
                    طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد
                  </p>
                </div>
                {/* <!--footer-row--> */}
                <div className="footer-row footer-logo-row footer-logo-row-v2">
                  {/* <!--title--> */}
                  <h5 className="footer-row-title">نماد اعتماد </h5>
                  {/* <!--imgs-wrapper--> */}
                  <div className="footer-row-img-wrapper">
                    {/* <!--img-box--> */}
                    <div className="footer-img-box">
                      {/* <!--img--> */}
                      <img
                        src={footerImg01}
                        alt="footer-img"
                        className="footer-img"
                      />
                    </div>
                    {/* <!--img-box--> */}
                    <div className="footer-img-box">
                      {/* <!--img--> */}
                      <img
                        src={footerImg02}
                        alt="footer-img"
                        className="footer-img"
                      />
                    </div>
                    {/* <!--img-box--> */}
                    <div className="footer-img-box">
                      {/* <!--img--> */}
                      <img
                        src={footerImg03}
                        alt="footer-img"
                        className="footer-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--copy-right-wrapper--> */}
              <div className="copy-right-wrapper-v2">
                {/* <!--right-side--> */}
                <p className="copy-right-right-text">
                  {/* <!--button--> */}
                  <a href="#header-v2" className="moving-top-button">
                    {/* <!--icon--> */}
                    <i className="fa-solid fa-arrow-up moving-top-icon"></i>
                  </a>
                  کلیه حقوق مادی و معنوی این سایت متعلق به فروشگاه زود گیفت
                  می‌باشد
                </p>
                {/* <!--left-side--> */}
                <div className="copy-right-left-side-wrapper">
                  {/* <!--logo-wrapper--> */}
                  <div className="copy-right-logo-wrapper">
                    <img src={logo02} alt="logo" className="copy-right-logo" />
                  </div>
                  {/* <!--text-box---> */}
                  <div className="copy-right-left-side-text-box">
                    {/* <!--text--> */}
                    <p className="copy-right-left-main-text">
                      © 2024 ZOODGIFT.COM
                    </p>
                    {/* <!--text--> */}
                    <p className="copy-right-left-sub-text">
                      All material and intellectual rights of this site belong
                      to ZoodGift store
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
      <ToastContainer toastClassName="toast-right" />
    </div>
  );
}

export default Product;
