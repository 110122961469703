import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../assets/css/style.css'
import '../assets/css/fonts.css'
import '../assets/css/media-query.css'
import '../assets/css/variables.css'

// icon
import { FaCircleXmark } from 'react-icons/fa6'
import { IoMdExit } from 'react-icons/io'
import { IoMenu } from 'react-icons/io5'
import {
  FaShoppingBasket,
  FaChartBar,
  FaQrcode,
  FaStore,
  FaCommentDots,
  FaRegCommentDots,
  FaShoppingBag,
  FaBook,
  FaArrowLeft,
  FaCaretSquareDown,
  FaEnvelopeOpenText,
  FaEnvelope,
  FaCaretSquareLeft,
  FaCaretSquareRight,
  FaChevronCircleDown,
  FaFileUpload,
} from 'react-icons/fa'
import { CiCirclePlus } from 'react-icons/ci'

import banner from '../assets/images/dashboard-banner-background.png'
import logo from '../assets/images/logo.png'
import lastOrder from '../assets/images/last-order-img.png'
import headphoneSvg from '../assets/images/headphone-headphones-svgrepo-com.svg'
import serviceBox from '../assets/images/service-box.png'
import bannerSupport from '../assets/images/dashboard-banner-support.png'

function SupportTicketList() {
  const navigate = useNavigate()

  return (
    <div className='body-white-color-2'>
      <div className='page-main-contaier body-white-color-2'>
        {/* <!--=========Main============--> */}
        <main className='main'>
          {/* <!--============Dashboard-section--> */}
          <section className='dashboard-sectoin'>
            {/* <!--setting-container--> */}
            <div className='container'>
              {/* <!--dashboard-content---> */}
              <div className='dashboard-content-wrapper'>
                {/* <!--==========input-for-controlling-the-funtionality========--> */}
                <input
                  type='checkbox'
                  className='side-nav-input-v1'
                  id='side-nav-input-v1'
                  hidden
                />
                {/* <!---side-nav--> */}
                <div className='dashboard-side-nav mini-section-box-style'>
                  {/* <!--icon--> */}
                  <label
                    for='side-nav-input-v1'
                    className='side-nav-close-label'
                  >
                    {/* <!--icon--> */}
                    <FaCircleXmark className='fa-solid fa-xmark' />
                  </label>
                  {/* <!--logo--> */}
                  <div className='side-nav-logo-wrapper'>
                    {/* <!--img--> */}
                    <img src={logo} alt='img' className='side-nav-logo' />
                    {/* <!--text--> */}
                    <h4 className='side-nav-logo-text'>زود گیفت</h4>
                  </div>
                  {/* <!--list--> */}
                  <ul className='side-nav-list'>
                    {/* <!--link-item--> */}
                    <a href='dashboard.html' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item '>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <IoMenu className='fa-solid fa-table-list side-nav-item-icon' />

                        {/* <!--text--> */}
                        <p className='side-nav-text'>داشبورد</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a
                      href='dashboard-see-orders.html'
                      className='side-nav-link'
                    >
                      {/* <!--item--> */}
                      <li className='side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaShoppingBasket className='fa-solid fa-basket-shopping side-nav-item-icon' />

                        {/* <!--text--> */}
                        <p className='side-nav-text'>مشاهده سفارشات</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='#' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item '>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaChartBar className='fa-solid fa-chart-line side-nav-item-icon' />

                        {/* <!--text--> */}
                        <p className='side-nav-text'>تراکنش اخیر</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a
                      href='dashboard-authentication.html'
                      className='side-nav-link'
                    >
                      {/* <!--item--> */}
                      <li className='side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaQrcode className='fa-solid fa-qrcode side-nav-item-icon' />
                        {/* <!--text--> */}
                        <p className='side-nav-text'>احراز هویت</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='#' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaStore className='fa-solid fa-store side-nav-item-icon' />

                        {/* <!--text--> */}
                        <p className='side-nav-text'>سفارش عمده</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='dashboard-support.html' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item active-side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaCommentDots className='fa-solid fa-comment-dots side-nav-item-icon' />
                        {/* <!--text--> */}
                        <p className='side-nav-text'>تیکت پشتیبانی</p>
                      </li>
                    </a>
                  </ul>
                  <div className='side-nav-down-link-wrapper'>
                    {/* <!--link--> */}
                    <a href='./index-2.html' className='side-nav-link-v2'>
                      بازگشت به فروشگاه
                    </a>
                  </div>
                </div>
                {/*  <!--dashboard-content--> */}
                <div className='dashboard-content'>
                  {/* <!--dashbord-top--> */}
                  <div className='dashboard-top-wrapper mini-section-box-style'>
                    {/* <!--right-side--> */}
                    <div className='dashboard-top-right-wrapper'>
                      {/* <!--link--> */}
                      <a
                        href='#'
                        className='dashboard-top-link dashboard-top-link-mobile'
                      >
                        {/* <!--icon--> */}
                        <IoMdExit className='fa-solid fa-right-from-bracket' />
                        خروج
                      </a>
                      {/* <!--lable--> */}
                      <label
                        for='side-nav-input-v1'
                        className='side-nav-open-label'
                      >
                        پنل کاربری
                      </label>
                    </div>
                    {/* <!--left-side--> */}
                    <div className='dashboard-top-left-wrapper'>
                      {/* <!--text-box--> */}
                      <div className='dashboard-left-text-box'>
                        {/* <!--link--> */}
                        <a href='#' className='dashboard-top-link'>
                          {/* <!--icon--> */}
                          <FaRegCommentDots className='fa-regular fa-comment-dots' />
                          پشتیبانی
                        </a>
                        <p className='dashboard-top-text'>
                          {/* <!--text--> */}
                          <span className='dashboard-top-text-span'>
                            ۰۹۱۶۰۲۶۵۶۶۱
                          </span>
                          {/* <!--line--> */}
                          <span className='dashboard-top-text-line-span'></span>
                          {/* <!--text--> */}
                          <span className='dashboard-top-text-span'>
                            محمدرضا عبادی
                          </span>
                        </p>
                      </div>
                      {/* <!--logo--> */}
                      <div className='dashboard-top-logo-wrapper'>
                        {/* <!--logo--> */}
                        <img
                          src={logo}
                          alt='img'
                          className='dashboard-log-img'
                        />
                      </div>
                    </div>
                  </div>
                  {/* <!--==dashboard-list=--> */}
                  <div className='dashboard-list-wrapper '>
                    {/* <!--input-for-controlling-the-funtionality--> */}
                    <input
                      type='radio'
                      name='list-info-input-radio'
                      id='list-info-finished'
                      className='list-info-input'
                      hidden
                    />
                    <input
                      type='radio'
                      name='list-info-input-radio'
                      id='list-in-progress'
                      className='list-info-input'
                      hidden
                    />
                    <input
                      type='radio'
                      name='list-info-input-radio'
                      id='list-all'
                      className='list-info-input'
                      hidden
                      checked
                    />
                    {/* <!--list-top-info--> */}
                    <div className='list-info-box'>
                      {/* <!--right--> */}
                      <div className='list-info-box-right'>
                        {/* <!--label--> */}
                        <label
                          for='list-in-progress'
                          className='list-info-label'
                        >
                          {/* <!--text--> */}
                          <p className='dashboard-text '>
                            {/* <!--icon--> */}
                            <span className='dashboard-circle-icon dashboard-circle-icon-orange'></span>
                            در حال بررسی
                          </p>
                          {/* <!--text--> */}
                          <p className='dashboard-text'>۰</p>
                        </label>
                        {/* <!--label--> */}
                        <label
                          for='list-info-finished'
                          className='list-info-label'
                        >
                          {/* <!--text--> */}
                          <p className='dashboard-text '>
                            {/* <!--icon--> */}
                            <span className='dashboard-circle-icon dashboard-circle-icon-green'></span>
                            تکمیل شده
                          </p>
                          {/* <!--text--> */}
                          <p className='dashboard-text'>۱</p>
                        </label>
                      </div>
                      {/* <!--left--> */}
                      <a
                        href='dashboard-support-form.html'
                        className='add-new-tiket-btn'
                      >
                        ثبت تیکت جدید
                        {/* <!--icon--> */}
                        <i className='fa-solid fa-circle-plus'></i>
                        <FaCircleXmark className='fa-solid fa-circle-plus' />
                      </a>
                    </div>
                    {/* <!--list-wrapper--> */}
                    <div className='dashboard-list-content-wrapper dashboard-list-all mini-section-box-style'>
                      {/* <!--top--> */}
                      <div className='dashboard-list-title-box grid-list'>
                        {/* <!--title--> */}
                        <h5 className='h5-dashboard-title text-align-center'>
                          شماره تیکت
                        </h5>
                        {/* <!--title--> */}
                        <h5 className='h5-dashboard-title text-align-center'>
                          عنوان تیکت
                        </h5>
                        {/* <!--title--> */}
                        <h5 className='h5-dashboard-title text-align-center'>
                          اهمیت
                        </h5>
                        {/* <!--title--> */}
                        <h5 className='h5-dashboard-title text-align-center'>
                          وضعیت تیکت
                        </h5>
                        {/* <!--title--> */}
                        <h5 className='h5-dashboard-title text-align-center'>
                          جزئیات
                        </h5>
                      </div>
                      {/* <!--line--> */}
                      <span className='under-line-v1 under-line-v1-media'></span>
                      {/* <!--item--> */}
                      <div className='dashboard-list-item-wrapper grid-list'>
                        {/* <!--text--> */}
                        <p
                          data-info='شماره تیکت'
                          className='dashboard-text text-align-center dashboard-list-text dashboard-text-gray-color'
                        >
                          ۱۰۱۲۵
                        </p>
                        {/* <!--text--> */}
                        <p
                          data-info='عنوان  تیکت'
                          className='dashboard-text  text-align-center dashboard-list-text'
                        >
                          گیفت کارت خریداری شده فعال نمیشه ...
                        </p>
                        {/* <!--text--> */}
                        <p
                          data-info='اهمیت'
                          className='dashboard-text text-align-center dashboard-list-text dashboard-text-gray-color'
                        >
                          زیاد
                        </p>
                        {/* <!--text--> */}
                        <p
                          data-info='وضعیت تیکت'
                          className='dashboard-text text-align-center dashboard-list-text'
                        >
                          {/* <!--icon--> */}
                          <span class='dashboard-circle-icon dashboard-circle-icon-green'></span>
                          تکمیل شده
                        </p>
                        {/* <!--link--> */}
                        <a
                          data-info='جزئیات'
                          href='#'
                          className='dashboard-text dashboard-list-link dashboard-text-gray-color'
                        >
                          مشاهده
                          {/* <!--icon--> */}
                          <i className='fa-solid fa-eye'></i>
                        </a>
                      </div>
                      {/* <!--line--> */}
                      <span className='under-line-v1 under-line-v1-media'></span>
                      {/* <!--item--> */}
                      <div className='dashboard-list-item-wrapper grid-list'>
                        {/* <!--text--> */}
                        <p
                          data-info='شماره تیکت'
                          className='dashboard-text text-align-center dashboard-list-text dashboard-text-gray-color'
                        >
                          ۱۰۱۲۵
                        </p>
                        {/* <!--text--> */}
                        <p
                          data-info='عنوان  تیکت'
                          className='dashboard-text  text-align-center dashboard-list-text'
                        >
                          گیفت کارت خریداری شده فعال نمیشه ...
                        </p>
                        {/* <!--text--> */}
                        <p
                          data-info='اهمیت'
                          className='dashboard-text text-align-center dashboard-list-text dashboard-text-gray-color'
                        >
                          زیاد
                        </p>
                        {/* <!--text--> */}
                        <p
                          data-info='وضعیت تیکت'
                          className='dashboard-text text-align-center dashboard-list-text'
                        >
                          {/* <!--icon--> */}
                          <span className='dashboard-circle-icon dashboard-circle-icon-orange'></span>
                          در حال بررسی
                        </p>
                        {/* <!--link--> */}
                        <a
                          data-info='جزئیات'
                          href='#'
                          className='dashboard-text dashboard-list-link dashboard-text-gray-color'
                        >
                          مشاهده
                          {/* <!--icon--> */}
                          <i className='fa-solid fa-eye'></i>
                        </a>
                      </div>
                      {/* <!--line--> */}
                      <span className='under-line-v1 under-line-v1-media'></span>
                      {/* <!--item--> */}
                      <div className='dashboard-list-item-wrapper grid-list'>
                        {/* <!--text--> */}
                        <p
                          data-info='شماره تیکت'
                          className='dashboard-text text-align-center dashboard-list-text dashboard-text-gray-color'
                        >
                          ۱۰۱۲۵
                        </p>
                        {/* <!--text--> */}
                        <p
                          data-info='عنوان  تیکت'
                          className='dashboard-text  text-align-center dashboard-list-text'
                        >
                          گیفت کارت خریداری شده فعال نمیشه ...
                        </p>
                        {/* <!--text--> */}
                        <p
                          data-info='اهمیت'
                          className='dashboard-text text-align-center dashboard-list-text dashboard-text-gray-color'
                        >
                          زیاد
                        </p>
                        {/* <!--text--> */}
                        <p
                          data-info='وضعیت تیکت'
                          className='dashboard-text text-align-center dashboard-list-text'
                        >
                          {/* <!--icon--> */}
                          <span className='dashboard-circle-icon dashboard-circle-icon-green'></span>
                          تکمیل شده
                        </p>
                        {/* <!--link--> */}
                        <a
                          data-info='جزئیات'
                          href='#'
                          className='dashboard-text dashboard-list-link dashboard-text-gray-color'
                        >
                          مشاهده
                          {/* <!--icon--> */}
                          <i className='fa-solid fa-eye'></i>
                        </a>
                      </div>
                      {/* <!--line--> */}
                      <span className='under-line-v1 under-line-v1-media'></span>
                      {/* <!--item--> */}
                      <div className='dashboard-list-item-wrapper grid-list'>
                        {/* <!--text--> */}
                        <p
                          data-info='شماره تیکت'
                          className='dashboard-text text-align-center dashboard-list-text dashboard-text-gray-color'
                        >
                          ۱۰۱۲۵
                        </p>
                        {/* <!--text--> */}
                        <p
                          data-info='عنوان  تیکت'
                          className='dashboard-text  text-align-center dashboard-list-text'
                        >
                          گیفت کارت خریداری شده فعال نمیشه ...
                        </p>
                        {/* <!--text--> */}
                        <p
                          data-info='اهمیت'
                          className='dashboard-text text-align-center dashboard-list-text dashboard-text-gray-color'
                        >
                          زیاد
                        </p>
                        {/* <!--text--> */}
                        <p
                          data-info='وضعیت تیکت'
                          className='dashboard-text text-align-center dashboard-list-text'
                        >
                          {/* <!--icon--> */}
                          <span className='dashboard-circle-icon dashboard-circle-icon-orange'></span>
                          در حال بررسی
                        </p>
                        {/* <!--link--> */}
                        <a
                          data-info='جزئیات'
                          href='#'
                          className='dashboard-text dashboard-list-link dashboard-text-gray-color'
                        >
                          مشاهده
                          {/* <!--icon--> */}
                          <i className='fa-solid fa-eye'></i>
                        </a>
                      </div>
                      {/* <!--line--> */}
                      <span className='under-line-v1 under-line-v1-media'></span>
                      {/* <!--item--> */}
                      <div className='dashboard-list-item-wrapper grid-list'>
                        {/* <!--text--> */}
                        <p
                          data-info='شماره تیکت'
                          className='dashboard-text text-align-center dashboard-list-text dashboard-text-gray-color'
                        >
                          ۱۰۱۲۵
                        </p>
                        {/* <!--text--> */}
                        <p
                          data-info='عنوان  تیکت'
                          className='dashboard-text  text-align-center dashboard-list-text'
                        >
                          گیفت کارت خریداری شده فعال نمیشه ...
                        </p>
                        {/* <!--text--> */}
                        <p
                          data-info='اهمیت'
                          className='dashboard-text text-align-center dashboard-list-text dashboard-text-gray-color'
                        >
                          زیاد
                        </p>
                        {/* <!--text--> */}
                        <p
                          data-info='وضعیت تیکت'
                          className='dashboard-text text-align-center dashboard-list-text'
                        >
                          {/* <!--icon--> */}
                          <span className='dashboard-circle-icon dashboard-circle-icon-green'></span>
                          تکمیل شده
                        </p>
                        {/* <!--link--> */}
                        <a
                          data-info='جزئیات'
                          href='#'
                          className='dashboard-text dashboard-list-link dashboard-text-gray-color'
                        >
                          مشاهده
                          {/* <!--icon--> */}
                          <i className='fa-solid fa-eye'></i>
                        </a>
                      </div>
                      {/* <!--line--> */}
                      <span className='under-line-v1 under-line-v1-media'></span>
                      {/* <!--item--> */}
                      <div className='dashboard-list-item-wrapper grid-list'>
                        {/* <!--text--> */}
                        <p
                          data-info='شماره تیکت'
                          className='dashboard-text text-align-center dashboard-list-text dashboard-text-gray-color'
                        >
                          ۱۰۱۲۵
                        </p>
                        {/* <!--text--> */}
                        <p
                          data-info='عنوان  تیکت'
                          className='dashboard-text  text-align-center dashboard-list-text'
                        >
                          گیفت کارت خریداری شده فعال نمیشه ...
                        </p>
                        {/* <!--text--> */}
                        <p
                          data-info='اهمیت'
                          className='dashboard-text text-align-center dashboard-list-text dashboard-text-gray-color'
                        >
                          زیاد
                        </p>
                        {/* <!--text--> */}
                        <p
                          data-info='وضعیت تیکت'
                          className='dashboard-text text-align-center dashboard-list-text'
                        >
                          {/* <!--icon--> */}
                          <span className='dashboard-circle-icon dashboard-circle-icon-orange'></span>
                          در حال بررسی
                        </p>
                        {/* <!--link--> */}
                        <a
                          data-info='جزئیات'
                          href='#'
                          className='dashboard-text dashboard-list-link dashboard-text-gray-color'
                        >
                          مشاهده
                          {/* <!--icon--> */}
                          <i className='fa-solid fa-eye'></i>
                        </a>
                      </div>
                      {/* <!--line--> */}
                      <span className='under-line-v1 under-line-v1-media'></span>
                      {/* <!--item--> */}
                      <div className='dashboard-list-item-wrapper grid-list'>
                        {/* <!--text--> */}
                        <p
                          data-info='شماره تیکت'
                          className='dashboard-text text-align-center dashboard-list-text dashboard-text-gray-color'
                        >
                          ۱۰۱۲۵
                        </p>
                        {/* <!--text--> */}
                        <p
                          data-info='عنوان  تیکت'
                          className='dashboard-text  text-align-center dashboard-list-text'
                        >
                          گیفت کارت خریداری شده فعال نمیشه ...
                        </p>
                        {/* <!--text--> */}
                        <p
                          data-info='اهمیت'
                          className='dashboard-text text-align-center dashboard-list-text dashboard-text-gray-color'
                        >
                          زیاد
                        </p>
                        {/* <!--text--> */}
                        <p
                          data-info='وضعیت تیکت'
                          className='dashboard-text text-align-center dashboard-list-text'
                        >
                          {/* <!--icon--> */}
                          <span className='dashboard-circle-icon dashboard-circle-icon-green'></span>
                          تکمیل شده
                        </p>
                        {/* <!--link--> */}
                        <a
                          data-info='جزئیات'
                          href='#'
                          className='dashboard-text dashboard-list-link dashboard-text-gray-color'
                        >
                          مشاهده
                          {/* <!--icon--> */}
                          <i className='fa-solid fa-eye'></i>
                        </a>
                      </div>
                      {/* <!--pagination--> */}
                      <div className='dashboard-list-pagination'>
                        {/* <!--list--> */}
                        <ul className='dashboard-pagination-list-v2'>
                          {/* <!--item--> */}
                          <li className='dashboard-pagination-item-v2 dashboard-pagination-active-v2'>
                            1
                          </li>
                          {/* <!--item--> */}
                          <li className='dashboard-pagination-item-v2'>2</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <ToastContainer toastClassName='toast-right' />
    </div>
  )
}

export default SupportTicketList
