import React, { useState } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../assets/css/style.css'
import '../assets/css/fonts.css'
import '../assets/css/media-query.css'
import '../assets/css/variables.css'

import vectorImg from '../assets/images/Vector.png'
import contactUsTitleImg from '../assets/images/contact-us-title-img.png'
import mailboxIcon from '../assets/images/Mailbox-3D-Icon-1.svg'
import socialIcon1 from '../assets/images/contact-us-social-icon-1.png'
import socialIcon2 from '../assets/images/contact-us-social-icon-2.png'
import socialIcon3 from '../assets/images/contact-us-social-icon-3.png'
import socialIcon4 from '../assets/images/contact-us-social-icon-4.png'

import { FaChevronCircleDown } from 'react-icons/fa'

function Contact() {
  return (
    <div className='body-white-color-2'>
      <main className='main'>
        <section className='contact-us-section-v1'>
          <div className='container'>
            <div className='contact-us-wrapper'>
              <div className='contact-us-info-wrapper contact-us-side'>
                <div className='product-title-img'>
                  <img
                    src={vectorImg}
                    alt='img'
                    className='product-vector-img'
                  />
                  <img
                    src={contactUsTitleImg}
                    alt='img'
                    className='contact-us-title-img'
                  />
                </div>
                <h4 className='contact-us-title-v3'>تماس با ما</h4>
                <img
                  src={mailboxIcon}
                  alt='img'
                  className='contact-us-main-img'
                />
                <div className='contact-us-info-down-wrapper'>
                  <ul className='contact-us-list-v1'>
                    <li className='contact-us-item'>
                      <h5 className='contact-us-title-v1'>تماس با ما :</h5>
                      <p className='contact-us-text-v1'>۰۹۳۳۲۵۲۳۶۶۳</p>
                    </li>
                    <span className='list-under-line'></span>
                    <li className='contact-us-item'>
                      <h5 className='contact-us-title-v1'>ساعت کاری :</h5>
                      <p className='contact-us-text-v1'>
                        شنبه تا پنج شنبه ۹-۲۰
                      </p>
                    </li>
                    <span className='list-under-line'></span>
                    <li className='contact-us-item'>
                      <h5 className='contact-us-title-v1'>آدرس :</h5>
                      <p className='contact-us-text-v1'>
                        خوزستان - بندر ماهشهر
                      </p>
                    </li>
                  </ul>
                  <h5 className='contact-us-title-v1 contact-us-social-title'>
                    شبکه اجتماعی :
                  </h5>
                  <div className='contact-us-socila-link-wrapper'>
                    <a href='/' className='contact-us-social-link'>
                      <img
                        src={socialIcon1}
                        alt='img'
                        className='contact-us-social-icon'
                      />
                    </a>
                    <a href='/' className='contact-us-social-link'>
                      <img
                        src={socialIcon2}
                        alt='img'
                        className='contact-us-social-icon'
                      />
                    </a>
                    <a href='/' className='contact-us-social-link'>
                      <img
                        src={socialIcon3}
                        alt='img'
                        className='contact-us-social-icon'
                      />
                    </a>
                    <a href='/' className='contact-us-social-link'>
                      <img
                        src={socialIcon4}
                        alt='img'
                        className='contact-us-social-icon'
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className='contact-us-message-wrapper contact-us-side'>
                <div className='line-title-wrapper contact-us-line-title-wrapper'>
                  <h4 className='line-title-text'>زود گیفت</h4>
                  <span className='line-title-line contact-us-title-line'></span>
                </div>
                <form action='#' className='form-v3'>
                  <div className='form-top-wrapper-v3'>
                    <div className='form-group-top-v3'>
                      <h4 className='form-group-title-v3'>
                        نام و نام خانوادگی :
                      </h4>
                      <input type='text' className='form-input-v3' required />
                    </div>
                    <div className='form-group-top-v3'>
                      <h4 className='form-group-title-v3'>ایمیل :</h4>
                      <input type='text' className='form-input-v3' required />
                    </div>
                  </div>
                  <h4 className='form-group-title-v3'>متن در خواست :</h4>
                  <textarea
                    name='contact-us-message-text-area'
                    className='form-v3-text-area'
                  ></textarea>
                  <div className='form-bottom-wrapper-v3'>
                    <p className='form-text-v3'>
                      نظرات ، پیشنهادات و انتقادات خود را با ما در میان بگذارید
                    </p>
                    <button className='form-v3-submit-btn'>
                      ثبت درخواست
                      <i className='fa-regular fa-envelope'></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className='contact-us-your-question-section'>
          <div className='container'>
            <div className='contact-us-your-question-wrapper'>
              <div className='line-title-wrapper contact-us-line-title-wrapper'>
                <h4 className='line-title-text'>
                  <i className='fa-regular fa-circle-question'></i>
                  سوالات متدوال
                </h4>
                <span className='line-title-line'></span>
              </div>
              <div className='contact-us-your-question-content-wrapper'>
                <input
                  type='checkbox'
                  className='question-input'
                  id='question-input-1'
                />
                <label
                  htmlFor='question-input-1'
                  className='question-label question-label-v2'
                >
                  <h5 className='question-text'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ؟
                    <FaChevronCircleDown className='fa-regular fa-square-caret-down question-icon question-icon-v2' />
                  </h5>
                  <p className='question-answer'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                    تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                    کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و
                    آینده شناخت فراوان جامعه و متخصصان را می طلبد
                  </p>
                </label>
                <input
                  type='checkbox'
                  className='question-input'
                  id='question-input-2'
                />
                <label
                  htmlFor='question-input-2'
                  className='question-label question-label-v2'
                >
                  <h5 className='question-text'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ؟
                    <FaChevronCircleDown className='fa-regular fa-square-caret-down question-icon question-icon-v2' />
                  </h5>
                  <p className='question-answer'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و
                    با استفاده از طراحان گرافیک است،
                  </p>
                </label>
                <input
                  type='checkbox'
                  className='question-input'
                  id='question-input-3'
                />
                <label
                  htmlFor='question-input-3'
                  className='question-label question-label-v2'
                >
                  <h5 className='question-text'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ؟
                    <FaChevronCircleDown className='fa-regular fa-square-caret-down question-icon question-icon-v2' />
                  </h5>
                  <p className='question-answer'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و
                    با استفاده از طراحان گرافیک است،
                  </p>
                </label>
                <input
                  type='checkbox'
                  className='question-input'
                  id='question-input-4'
                />
                <label
                  htmlFor='question-input-4'
                  className='question-label question-label-v2'
                >
                  <h5 className='question-text'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ؟
                    <FaChevronCircleDown className='fa-regular fa-square-caret-down question-icon question-icon-v2' />
                  </h5>
                  <p className='question-answer'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                    تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                    کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و
                    آینده شناخت فراوان جامعه و متخصصان را می طلبد
                  </p>
                </label>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ToastContainer toastClassName='toast-right' />
    </div>
  )
}

export default Contact
