import React, { useEffect, useState } from "react";
import "../assets/css/style.css";
import "../assets/css/fonts.css";
import "../assets/css/media-query.css";
import "../assets/css/variables.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// image

import logo from "../assets/images/nav-v2-logo.png";
import logo02 from "../assets/images/logo.png";
import footerlogo from "../assets/images/svg-logo.png";
import footerImg01 from "../assets/images/footer-img-1.png";
import footerImg02 from "../assets/images/footer-img-2.png";
import footerImg03 from "../assets/images/footer-img-3.png";

import teachbanner from "../assets/images/teach-page-banner-background.png";
import teachimg from "../assets/images/teach-page-banner-img.png";
import teachlogo from "../assets/images/svg-logo.png";
import postimg from "../assets/images/teach-box-img.png";

import { IoMdMenu } from "react-icons/io";
import { FaRegWindowClose } from "react-icons/fa";
import { CiLogin } from "react-icons/ci";
import { RiShoppingBasketFill } from "react-icons/ri";
// منو
import { useNavigate } from "react-router-dom";
import axios from "axios";

// آیکون

import { FaArrowUp } from "react-icons/fa";

function Teach() {
  // نیازمندی منو

  const [productCardNumber, setProductCardNumber] = useState(0);

  // هندل رفتن به سبد خرید
  const handleBasketClick = () => {
    navigate("/card-page");
  };

  const navigate = useNavigate();

  // هندل رفتن به داشبورد
  const handleProfileClick = (event) => {
    event.preventDefault(); // جلوگیری از رفتار پیش فرض لینک
    const accessToken = sessionStorage.getItem("access_token");
    if (accessToken) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const accessToken = sessionStorage.getItem("access_token");
    setIsLoggedIn(!!accessToken);
  }, []);

  // تعداد محصولات در سبد خرید

  const [cartData, setCartData] = useState(null);

  const fetchCartData = async () => {
    const config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://api.zoodgift.com/api/cart",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
      },
    };

    try {
      const response = await axios.request(config);
      if (response.status === 404 || response.data.statusCode === 404) {
        // Handle empty cart case
      } else {
        setCartData(response.data);
        setProductCardNumber(response.data.data.products.length);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.error("سبد محصول شما خالی است");
      } else {
        toast.error("خطا در دریافت داده‌های سبد خرید");
      }
      console.log(error);
    }
  };

  // برای نمایش در لود اولیه
  useEffect(() => {
    fetchCartData();
  }, []);

  // -------------

  return (
    <div className="body-white-color-2">
      <div className="header-top-main-wrapper">
        {/* <!--input-for-controlling-the-functionality--> */}
        <input
          type="checkbox"
          className="mobile-nav-input mobile-nav-input-v2"
          id="mobile-nav-check"
          hidden
        />
        <div className="header-top-wrapper">
          {/* <!--setting-contaienr--> */}
          <div className="container">
            {/* <!--navigation--> */}
            <nav className="navigation-v2">
              {/* <!--logo--> */}
              <a href="./" className="nav-v2-logo-wrapper">
                {/* <!--img--> */}
                <img src={logo} alt="Logo" className="nav-v2-logo" />
              </a>
              {/* <!--list--> */}
              <ul className="nav-v2-list">
                {/* <!--item--> */}
                <li className="nav-v2-item">
                  <a href="./product-page.html" className="nav-v2-item-link">
                    لیست گیفت کارت
                  </a>
                </li>
                {/* <!--item--> */}
                <li className="nav-v2-item">
                  <a href="/teach" className="nav-v2-item-link">
                    آموزش استفاده گیفت کارت
                  </a>
                </li>
                {/* <!--item--> */}
                <li className="nav-v2-item">
                  <a href="/blog" className="nav-v2-item-link">
                    بلاگ
                  </a>
                </li>
                {/* <!--item--> */}
                <li className="nav-v2-item">
                  <a href="/contact" className="nav-v2-item-link">
                    تماس با ما
                  </a>
                </li>
              </ul>
              {/* <!--==mobile-nav==--> */}
              {/* <!--open-icon--> */}
              <label
                for="mobile-nav-check"
                className="mobile-nav-open-label mobile-nav-btns"
              >
                <IoMdMenu className="fa-solid fa-bars open-nav-icon" />
              </label>

              {/* <!--buttons--> */}
              <div className="nav-v2-links-wrapper">
                <div className="shop-link-v2-wrapper">
                  {/* <!--number--> */}
                  <span className="shop-link-number">
                    {productCardNumber.toLocaleString("fa-IR")}
                  </span>
                  {/* <!--link(Shop-link)--> */}
                  <a href="/card-page" className="nav-v2-link nav-v2-shop-link">
                    {/* <!--icon--> */}

                    <RiShoppingBasketFill
                      className="fa-solid fa-basket-shopping"
                      onClick={handleBasketClick}
                    />
                  </a>
                </div>
                {/* <!--link(login-link)--> */}
                <a
                  href="/"
                  className="nav-v2-link nav-v2-Login-link"
                  onClick={handleProfileClick}
                >
                  {isLoggedIn ? "داشبورد" : "ورود به حساب"}
                </a>
                {/* <!--login--> */}
                <a
                  href="./login.html"
                  className="nav-login-btn nav-v2-responsive-login"
                >
                  <span className="login-text">ورود</span>

                  <CiLogin className="fa-solid fa-arrow-right-to-bracket nav-login-icon" />
                </a>
              </div>
            </nav>
          </div>
        </div>
        {/* <!--nav-list--> */}
        <ul className="mobile-nav-list">
          {/* <!--close-icon--> */}
          <label
            for="mobile-nav-check"
            className="mobile-nav-close-label mobile-nav-btns"
          >
            <FaRegWindowClose className="fa-solid fa-x open-close-icon" />
          </label>
          {/* <!--nav-item--> */}
          <li className="mobile-nav-item">
            <a href="./index-2.html" className="mobile-nav-link">
              خانه
            </a>
          </li>
          {/* <!--nav-item--> */}
          <li className="mobile-nav-item">
            <a href="./product-page.html" className="mobile-nav-link">
              گیفت کارت
            </a>
          </li>
          {/* <!--nav-item--> */}
          <li className="mobile-nav-item">
            <a href="./teach-page.html" className="mobile-nav-link">
              آموزش استفاده گیفت کارت
            </a>
          </li>
          {/* <!--nav-item--> */}
          <li className="mobile-nav-item">
            <a href="./error-404.html" className="mobile-nav-link">
              راهنمای خرید گیفت کارت
            </a>
          </li>
          {/* <!--nav-item--> */}
          <li className="mobile-nav-item">
            <a href="./blog.html" className="mobile-nav-link">
              بلاگ
            </a>
          </li>
          {/* <!--nav-item--> */}
          <li className="mobile-nav-item">
            <a href="./error-404.html" className="mobile-nav-link">
              تماس با ما
            </a>
          </li>
        </ul>
      </div>

      <header className="header-v2" id="header-v2">
        {/* setting-container */}
        <div className="container">
          {/* teach-banner */}
          <div className="banner-v3-wrapper">
            {/* banner */}
            <div className="banner-v3">
              {/* background-img */}
              <img
                src={teachbanner}
                alt="img"
                className="banner-v3-background-img"
              />
              {/* banner-img */}
              <img src={teachimg} alt="img" className="banner-v3-img" />
              {/* logo-img */}
              <img src={teachlogo} alt="img" className="logo-img-banner" />
              {/* text-box */}
              <div className="banner-v3-text-box">
                {/* title */}
                <h1 className="banner-v3-title">
                  آموزش وارد کردن گیفت کارت
                  <span className="banner-v3-sub-title">
                    تمامیه آموزشات به صورت مرحله مرحله و به همراه ویدیو می باشد
                  </span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* <!--=======Main========--> */}
      <main className="main">
        {/* setting-container */}
        <div className="container">
          {/* warning-box */}
          <div className="warning-box-v2">
            {/* icon-box */}
            <div className="warning-v2-icon-box">
              {/* icon */}
              <i className="fa-solid fa-triangle-exclamation"></i>
            </div>
            {/* text */}
            <p className="warning-text-v2">
              نکته مهم :  تمام مراحل وارد کردن گیفت کارت را با آی پی غیر از
              ایران انجام دهید. زود گیفت مسئولیتی در قبال عواقب وارد کردن گیفت
              کارت با آی پی ایران ندارد.
            </p>
          </div>
        </div>

        {/* teach-box-section */}
        <section className="teach-box-section">
          {/* setting-container */}
          <div className="container">
            {/* teach-box-wrapper */}
            <div className="grid-3-columns-wrapper">
              {Array(9)
                .fill(0)
                .map((_, index) => (
                  <a href="/" className="teach-box-link" key={index}>
                    {/* box */}
                    <div className="teach-box">
                      {/* img */}
                      <img src={postimg} alt="img" className="teach-box-img" />
                      {/* title */}
                      <h3 className="card-title-v2">گیفت کارت اپل</h3>
                    </div>
                  </a>
                ))}
            </div>
          </div>
        </section>
      </main>

      {/* <!--=========Footer==============--> */}
      <footer className="footer-v2 ">
        {/* <!--logo--> */}
        <img src={footerlogo} alt="logo" className="footer-logo" />
        {/* <!--===setting-container===--> */}
        <div className="bigger-container">
          {/* <!--footer-content-wrapper--> */}
          <div className="footer-content-wrapper-v2">
            {/* <!--footer-top-wrapper--> */}
            <div className="footer-top-wrapper-v2">
              {/* <!--right-side--> */}
              <div className="footer-top-right-wrapper-v2">
                {/* <!--title--> */}
                <h5 className="footer-row-title footer-row-title-v2">
                  خدمات ما
                </h5>
                <div className="footer-list-wrapper-v2">
                  {/* <!--list--> */}
                  <ul className="footer-list footer-list-v2">
                    {/* <!--item--> */}
                    <li className="footer-item">
                      <a href="./error-404.html" className="footer-item-link">
                        خرید گیفت کارت اپل
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className="footer-item">
                      <a href="./error-404.html" className="footer-item-link">
                        خرید گیفت کارت پلی استیشن
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className="footer-item">
                      <a href="./error-404.html" className="footer-item-link">
                        خرید گیفت کارت استیم{" "}
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className="footer-item">
                      <a href="./error-404.html" className="footer-item-link">
                        خرید گیفت کارت ایکس باکس
                      </a>
                    </li>
                  </ul>
                  {/* <!--list--> */}
                  <ul className="footer-list footer-list-v2">
                    <li className="footer-item">
                      <a href="./error-404.html" className="footer-item-link">
                        خرید گیفت کارت گوگل پلی
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className="footer-item">
                      <a href="./error-404.html" className="footer-item-link">
                        خرید گیفت کارت پلی استیشن پلاس
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className="footer-item">
                      <a href="./error-404.html" className="footer-item-link">
                        خرید یوسی پابجی{" "}
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className="footer-item">
                      <a href="./error-404.html" className="footer-item-link">
                        خرید جم کلش اف کلنز
                      </a>
                    </li>
                  </ul>
                  {/* <!--list--> */}
                  <ul className="footer-list footer-list-v2">
                    <li className="footer-item">
                      <a href="./error-404.html" className="footer-item-link">
                        خرید اشتراک پریمیوم تلگرام
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className="footer-item">
                      <a href="./error-404.html" className="footer-item-link">
                        خرید اشتراک اسپاتیفای
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className="footer-item">
                      <a href="./error-404.html" className="footer-item-link">
                        خرید لایسنس ادوبی{" "}
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className="footer-item">
                      <a href="./error-404.html" className="footer-item-link">
                        خرید ویباکس فورتنایت
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer-top-left-wrapper-v2">
                {/* <!--title--> */}
                <h5 className="footer-row-title footer-row-title-v2">
                  دسترسی سریع
                </h5>
                <div className="footer-list-wrapper-v2">
                  {/* <!--list--> */}
                  <ul className="footer-list footer-list-v2">
                    {/* <!--item--> */}
                    <li className="footer-item">
                      <a href="./blog.html" className="footer-item-link">
                        بلاگ زود گیفت{" "}
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className="footer-item">
                      <a href="./error-404.html" className="footer-item-link">
                        شرایط و قوانین خرید{" "}
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className="footer-item">
                      <a href="./error-404.html" className="footer-item-link">
                        حساب کاربری
                      </a>
                    </li>
                    {/* <!--item--> */}
                    <li className="footer-item">
                      <a href="./error-404.html" className="footer-item-link">
                        تماس با ما
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <!--footer-middle-wrapper--> */}
            <div className="footer-middle-wrapper-v2">
              {/* <!--phone-number-box--> */}
              <div className="footer-phone-number-box">
                {/* <!--title--> */}
                <h5 className="footer-row-title">اطلاعات تماس</h5>
                {/* <!--footer-middle-text--> */}
                <p className="footer-middle-text">
                  <a href="./error-404.html" className="middle-text-link">
                    ۰۶۱۵۲۳۳۳۷۹۴
                  </a>{" "}
                  |{" "}
                  <a href="./error-404.html" className="middle-text-link">
                    ۰۹۱۶۰۲۶۵۶۶۱
                  </a>
                </p>
              </div>
              {/* <!--socila-box--> */}
              <div className="footer-social-box">
                {/* <!--title--> */}
                <h5 className="footer-row-title">زود گیفت در شبکه اجتماعی</h5>
                {/* <!--footer-middle-text--> */}
                <p className="footer-middle-text-social">
                  <a href="./error-404.html" className="middle-text-link">
                    تلگرام
                  </a>
                  <a href="./error-404.html" className="middle-text-link">
                    اینستاگرام
                  </a>
                  <a href="./error-404.html" className="middle-text-link">
                    یوتیوب
                  </a>
                </p>
              </div>
            </div>
            {/* <!--footer-bottom-wrapper--> */}
            <div className="footer-bottom-wrapper">
              {/* <!--footer-row--> */}
              <div className="footer-row footer-big-row-v2">
                {/* <!--title--> */}
                <h5 className="footer-row-title-v2 footer-row-title">
                  تجربه‌ای متفاوت در خرید گیفت کارت
                </h5>
                {/* <!--text--> */}
                <p className="footer-text footer-text-v2">
                  تجربه‌ای متفاوت از خرید گیفت کارت را با ما تجربه کنید. با
                  زودگیفت، می‌توانید به راحتی انواع کارت‌های هدیه محبوب از جمله
                  گیفت کارت آیتونز، گوگل پلی، پلی استیشن، ایکس باکس، اسپاتیفای،
                  استیم، تلگرام، پابجی موبایل، گیم پس، مسترکارت و بسیاری دیگر را
                  خریداری کنید. با ما، لذت یک خرید آسان و سریع را تجربه کنید و
                  از پشتیبانی حرفه‌ای و مطمئن بهره‌مند شوید.
                </p>
              </div>
              {/* <!--footer-row--> */}
              <div className="footer-row footer-logo-row footer-logo-row-v2">
                {/* <!--title--> */}
                <h5 className="footer-row-title">نماد اعتماد </h5>
                {/* <!--imgs-wrapper--> */}
                <div className="footer-row-img-wrapper">
                  {/* <!--img-box--> */}
                  <div className="footer-img-box">
                    {/* <!--img--> */}
                    <img
                      src={footerImg01}
                      alt="footer-img"
                      className="footer-img"
                    />
                  </div>
                  {/* <!--img-box--> */}
                  <div className="footer-img-box">
                    {/* <!--img--> */}
                    <img
                      src={footerImg02}
                      alt="footer-img"
                      className="footer-img"
                    />
                  </div>
                  {/* <!--img-box--> */}
                  <div className="footer-img-box">
                    {/* <!--img--> */}
                    <img
                      src={footerImg03}
                      alt="footer-img"
                      className="footer-img"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <!--copy-right-wrapper--> */}
            <div className="copy-right-wrapper-v2">
              {/* <!--right-side--> */}
              <p className="copy-right-right-text">
                {/* <!--button--> */}
                <a href="#header-v2" className="moving-top-button">
                  {/* <!--icon--> */}

                  <FaArrowUp className="fa-solid fa-arrow-up moving-top-icon" />
                </a>
                کلیه حقوق مادی و معنوی این سایت متعلق به فروشگاه زود گیفت
                می‌باشد
              </p>
              {/* <!--left-side--> */}
              <div className="copy-right-left-side-wrapper">
                {/* <!--logo-wrapper--> */}
                <div className="copy-right-logo-wrapper">
                  <img src={logo02} alt="logo" className="copy-right-logo" />
                </div>
                {/* <!--text-box---> */}
                <div className="copy-right-left-side-text-box">
                  {/* <!--text--> */}
                  <p className="copy-right-left-main-text">
                    © 2024 ZOODGIFT.COM
                  </p>
                  {/* <!--text--> */}
                  <p className="copy-right-left-sub-text">
                    All material and intellectual rights of this site belong to
                    ZoodGift store
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* اعلان ها  */}
      <ToastContainer toastClassName="toast-right" />
    </div>
  );
}

export default Teach;
