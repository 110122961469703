import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../assets/css/style.css'
import '../assets/css/fonts.css'
import '../assets/css/media-query.css'
import '../assets/css/variables.css'

// icon
import { FaCircleXmark } from 'react-icons/fa6'
import { IoMdExit } from 'react-icons/io'
import { IoMenu } from 'react-icons/io5'
import {
  FaShoppingBasket,
  FaChartBar,
  FaQrcode,
  FaStore,
  FaCommentDots,
  FaRegCommentDots,
  FaShoppingBag,
  FaBook,
  FaArrowLeft,
  FaCaretSquareDown,
  FaEnvelopeOpenText,
  FaEnvelope,
  FaCaretSquareLeft,
  FaCaretSquareRight,
} from 'react-icons/fa'
import { CiCirclePlus } from 'react-icons/ci'

import banner from '../assets/images/dashboard-banner-background.png'
import logo from '../assets/images/logo.png'
import lastOrder from '../assets/images/last-order-img.png'
import headphoneSvg from '../assets/images/headphone-headphones-svgrepo-com.svg'
import serviceBox from '../assets/images/service-box.png'
import bannerSupport from '../assets/images/dashboard-banner-support.png'

function Support() {
  const navigate = useNavigate()

  return (
    <div className='body-white-color-2'>
      <div className='page-main-contaier body-white-color-2'>
        {/* <!--=========Main============--> */}
        <main className='main'>
          {/* <!--============Dashboard-section--> */}
          <section className='dashboard-sectoin'>
            {/* <!--setting-container--> */}
            <div className='container'>
              {/* <!--dashboard-content---> */}
              <div className='dashboard-content-wrapper'>
                {/* <!--==========input-for-controlling-the-funtionality========--> */}
                <input
                  type='checkbox'
                  className='side-nav-input-v1'
                  id='side-nav-input-v1'
                  hidden
                />
                {/* <!---side-nav--> */}
                <div className='dashboard-side-nav mini-section-box-style'>
                  {/* <!--icon--> */}
                  <label
                    for='side-nav-input-v1'
                    className='side-nav-close-label'
                  >
                    {/* <!--icon--> */}
                    <FaCircleXmark className='fa-solid fa-xmark' />
                  </label>
                  {/* <!--logo--> */}
                  <div className='side-nav-logo-wrapper'>
                    {/* <!--img--> */}
                    <img src={logo} alt='img' className='side-nav-logo' />
                    {/* <!--text--> */}
                    <h4 className='side-nav-logo-text'>زود گیفت</h4>
                  </div>
                  {/* <!--list--> */}
                  <ul className='side-nav-list'>
                    {/* <!--link-item--> */}
                    <a href='/dashboard' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item '>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}
                        <IoMenu className='fa-solid fa-table-list side-nav-item-icon' />
                        {/* <!--text--> */}
                        <p className='side-nav-text'>داشبورد</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a
                      href='/dashboard/orders'
                      className='side-nav-link'
                    >
                      {/* <!--item--> */}
                      <li className='side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}
                        <FaShoppingBasket className='fa-solid fa-basket-shopping side-nav-item-icon' />
                        {/* <!--text--> */}
                        <p className='side-nav-text'>مشاهده سفارشات</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='#' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item '>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}
                        <FaChartBar className='fa-solid fa-chart-line side-nav-item-icon' />
                        {/* <!--text--> */}
                        <p className='side-nav-text'>تراکنش اخیر</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a
                      href='/dashboard/authentication'
                      className='side-nav-link'
                    >
                      {/* <!--item--> */}
                      <li className='side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}
                        <FaQrcode className='fa-solid fa-qrcode side-nav-item-icon' />
                        {/* <!--text--> */}
                        <p className='side-nav-text'>احراز هویت</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='#' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}
                        <FaStore className='fa-solid fa-store side-nav-item-icon' />
                        {/* <!--text--> */}
                        <p className='side-nav-text'>سفارش عمده</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='/dashboard/support' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item active-side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}
                        <FaCommentDots className='fa-solid fa-comment-dots side-nav-item-icon' />
                        {/* <!--text--> */}
                        <p className='side-nav-text'>تیکت پشتیبانی</p>
                      </li>
                    </a>
                  </ul>
                  <div className='side-nav-down-link-wrapper'>
                    {/* <!--link--> */}
                    <a href='/' className='side-nav-link-v2'>
                      بازگشت به فروشگاه
                    </a>
                  </div>
                </div>
                {/*  <!--dashboard-content--> */}
                <div className='dashboard-content'>
                  {/* <!--dashbord-top--> */}
                  <div className='dashboard-top-wrapper mini-section-box-style'>
                    {/* <!--right-side--> */}
                    <div className='dashboard-top-right-wrapper'>
                      {/* <!--link--> */}
                      <a
                        href='#'
                        className='dashboard-top-link dashboard-top-link-mobile'
                      >
                        {/* <!--icon--> */}
                        <IoMdExit className='fa-solid fa-right-from-bracket' />
                        خروج
                      </a>
                      {/* <!--lable--> */}
                      <label
                        for='side-nav-input-v1'
                        className='side-nav-open-label'
                      >
                        پنل کاربری
                      </label>
                    </div>
                    {/* <!--left-side--> */}
                    <div className='dashboard-top-left-wrapper'>
                      {/* <!--text-box--> */}
                      <div className='dashboard-left-text-box'>
                        {/* <!--link--> */}
                        <a href='#' className='dashboard-top-link'>
                          {/* <!--icon--> */}
                          <FaRegCommentDots className='fa-regular fa-comment-dots' />
                          پشتیبانی
                        </a>
                        <p className='dashboard-top-text'>
                          {/* <!--text--> */}
                          <span className='dashboard-top-text-span'>
                            ۰۹۱۶۰۲۶۵۶۶۱
                          </span>
                          {/* <!--line--> */}
                          <span className='dashboard-top-text-line-span'></span>
                          {/* <!--text--> */}
                          <span className='dashboard-top-text-span'>
                            محمدرضا عبادی
                          </span>
                        </p>
                      </div>
                      {/* <!--logo--> */}
                      <div className='dashboard-top-logo-wrapper'>
                        {/* <!--logo--> */}
                        <img
                          src={logo}
                          alt='img'
                          className='dashboard-log-img'
                        />
                      </div>
                    </div>
                  </div>
                  {/* <!--support-box--> */}
                  <div className='dashboard-support-box-v2 mini-section-box-style'>
                    {/* <!--top-wrapper--> */}
                    <div className='support-box-v2-top-wrapper'>
                      {/* <!--text--> */}
                      <p className='dashboard-text'>شماره تیکت</p>
                      {/* <!--text--> */}
                      <p className='dashboard-text'>عنوان تیکت</p>
                      {/* <!--text--> */}
                      <p className='dashboard-text'>اهمیت</p>
                      {/* <!--text--> */}
                      <p className='dashboard-text'>وضعیت تیکت</p>
                      {/* <!--text--> */}
                      <p className='dashboard-text'>جزئیات</p>
                    </div>
                    {/* <!--line--> */}
                    <span className='under-line-v1'></span>
                    {/* <!--banner-wrapper--> */}
                    <div className='dashboard-support-banner-v2'>
                      {/* <!--img--> */}
                      <img
                        src={bannerSupport}
                        alt='img'
                        className='dashboard-support-banner-img-v2'
                      />
                    </div>
                    {/* <!--text-box--> */}
                    <div className='dashboard-support-text-box'>
                      {/* <!--text--> */}
                      <p className='support-text-box-v2'>
                        <span className='support-text-box-v2-span'>
                          هنوز تیکتی ثبت نکرده‌اید
                        </span>
                        تیکت‌های شما از زمان ثبت تا پایان یافتن در اینجا نمایش
                        داده می‌شود.
                      </p>
                      {/* <!--button--> */}
                      <button className='dashboard-support-btn'>
                        ثبت تیکت جدید
                        {/* <!--icon--> */}
                        <CiCirclePlus className='fa-solid fa-circle-plus' />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <ToastContainer toastClassName='toast-right' />
    </div>
  )
}

export default Support
