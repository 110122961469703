import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css'

import Home from './pages/Home'
import Login from './pages/Login'
import Register from './pages/Register'
import NotFound from './pages/404'
import BlogCategory from './pages/BlogCategory'
import Teach from './pages/Teach'
import Blog from './pages/Blog'
import Contact from './pages/ContactUS'
import License from './pages/license'
import Basket from './pages/ShopBasket'
import ProductPage from './pages/Product'
import Profile from './pages/Profile'
import Dashboard from './pages/Dashboard'
import Authentication from './pages/Authentication'
import Orders from './pages/Orders'
import Support from './pages/Support'
import Ticket from './pages/SupportTicket'
import List from './pages/SupportTicketList'
import Detail from './pages/SupportTicketDetail'
import ProductDtail from './pages/ProductDetail'

function App() {
  return (
    <Router>
      <div className='App'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/404' element={<NotFound />} />
          <Route path='/blog-category' element={<BlogCategory />} />
          <Route path='/teach' element={<Teach />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/license' element={<License />} />
          {/* <Route path='/product-page' element={<Product />} /> */}
          <Route path='/product-page' element={<ProductPage />} />
          <Route path='/card-page' element={<Basket />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route
            path='/dashboard/authentication'
            element={<Authentication />}
          />
          <Route path='/dashboard/orders' element={<Orders />} />
          <Route path='/dashboard/support' element={<Support />} />
          <Route path='/dashboard/support/ticket' element={<Ticket />} />
          <Route path='/dashboard/support/ticket/list' element={<List />} />
          <Route path='/dashboard/support/ticket/details' element={<Detail />} />
          <Route path='/product-detail' element={<ProductDtail />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App
