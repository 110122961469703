import React from 'react'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../assets/css/style.css'
import '../assets/css/fonts.css'
import '../assets/css/media-query.css'
import '../assets/css/variables.css'

function Profile() {
  return (
    <div className='body-white-color-2'>
      <h6>Profile</h6>
      <ToastContainer toastClassName='toast-right' />
    </div>
  )
}

export default Profile
