import React from 'react'
import '../assets/css/style.css'
import '../assets/css/fonts.css'
import '../assets/css/media-query.css'
import '../assets/css/variables.css'

import notFound from '../assets/images/404-image.png'

function Login() {
  return (
    <div className='body-white-color-2'>
      <div className='e-404-main-wrapper'>
        {/* <!--text-box--> */}
        <div className='e-404-text-box'>
          {/* <!--top-wrapper--> */}
          <div className='e-404-top-wrapper'>
            {/* <!--progress-circle--> */}
            <div className='progress-circle e-404-progress-circle'>
              <span className='progress-value e-404-progress-value'>10</span>
            </div>
            {/* <!--text--> */}
            <p className='e-404-text'>
              زمان باقی مانده برای بازگشت به صفحه اصلی
            </p>
          </div>
          {/* <!--content-----> */}
          <div className='e-404-bottom-wrapper'>
            {/* <!--title--> */}
            <h1 className='e-404-title'>سلام، انگار گم شدی ...</h1>
            {/* <!--text--> */}
            <p className='e-404-text'>
              صفحه مورد نظر پیدا نشد یا پاک شده است، شما می‌توانید به صفحه اصلی
              برگردید و یا از طریق جستجو فایل یا صفحه مورد نظرتان را پیدا کنید
            </p>
            {/* <!--search-box--> */}
            <div className='search-box'>
              {/* <!--input--> */}
              <input
                type='search'
                className='search-box-input'
                placeholder='به دنبال چه چیزی میگشتی؟'
              />
              {/* <!--icon--> */}
              <i className='fa-solid fa-magnifying-glass search-box-icon'></i>
            </div>
          </div>
        </div>
        {/* <!--img--> */}
        <div className='e-404-banner'>
          <img src={notFound} alt='img' className='e-404-img' />
        </div>
      </div>
    </div>
  )
}



export default Login
