import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../assets/css/style.css'
import '../assets/css/fonts.css'
import '../assets/css/media-query.css'
import '../assets/css/variables.css'

// icon
import { FaCircleXmark } from 'react-icons/fa6'
import { IoMdExit } from 'react-icons/io'
import { IoMenu } from 'react-icons/io5'
import {
  FaShoppingBasket,
  FaChartBar,
  FaQrcode,
  FaStore,
  FaCommentDots,
  FaRegCommentDots,
  FaShoppingBag,
  FaBook,
  FaArrowLeft,
  FaCaretSquareDown,
  FaEnvelopeOpenText,
  FaEnvelope,
  FaCaretSquareLeft,
  FaCaretSquareRight,
  FaChevronCircleDown,
  FaFileUpload,
} from 'react-icons/fa'
import { CiCirclePlus } from 'react-icons/ci'

import banner from '../assets/images/dashboard-banner-background.png'
import logo from '../assets/images/logo.png'
import lastOrder from '../assets/images/last-order-img.png'
import headphoneSvg from '../assets/images/headphone-headphones-svgrepo-com.svg'
import serviceBox from '../assets/images/service-box.png'
import bannerSupport from '../assets/images/dashboard-banner-support.png'

function SupportTicketDetail() {
  const navigate = useNavigate()

  return (
    <div className='body-white-color-2'>
      <div className='page-main-contaier body-white-color-2'>
        {/* <!--=========Main============--> */}
        <main className='main'>
          {/* <!--============Dashboard-section--> */}
          <section className='dashboard-sectoin'>
            {/* <!--setting-container--> */}
            <div className='container'>
              {/* <!--dashboard-content---> */}
              <div className='dashboard-content-wrapper'>
                {/* <!--==========input-for-controlling-the-funtionality========--> */}
                <input
                  type='checkbox'
                  className='side-nav-input-v1'
                  id='side-nav-input-v1'
                  hidden
                />
                {/* <!---side-nav--> */}
                <div className='dashboard-side-nav mini-section-box-style'>
                  {/* <!--icon--> */}
                  <label
                    for='side-nav-input-v1'
                    className='side-nav-close-label'
                  >
                    {/* <!--icon--> */}
                    <FaCircleXmark className='fa-solid fa-xmark' />
                  </label>
                  {/* <!--logo--> */}
                  <div className='side-nav-logo-wrapper'>
                    {/* <!--img--> */}
                    <img src={logo} alt='img' className='side-nav-logo' />
                    {/* <!--text--> */}
                    <h4 className='side-nav-logo-text'>زود گیفت</h4>
                  </div>
                  {/* <!--list--> */}
                  <ul className='side-nav-list'>
                    {/* <!--link-item--> */}
                    <a href='dashboard.html' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item '>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <IoMenu className='fa-solid fa-table-list side-nav-item-icon' />

                        {/* <!--text--> */}
                        <p className='side-nav-text'>داشبورد</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a
                      href='dashboard-see-orders.html'
                      className='side-nav-link'
                    >
                      {/* <!--item--> */}
                      <li className='side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaShoppingBasket className='fa-solid fa-basket-shopping side-nav-item-icon' />

                        {/* <!--text--> */}
                        <p className='side-nav-text'>مشاهده سفارشات</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='#' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item '>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaChartBar className='fa-solid fa-chart-line side-nav-item-icon' />

                        {/* <!--text--> */}
                        <p className='side-nav-text'>تراکنش اخیر</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a
                      href='dashboard-authentication.html'
                      className='side-nav-link'
                    >
                      {/* <!--item--> */}
                      <li className='side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaQrcode className='fa-solid fa-qrcode side-nav-item-icon' />
                        {/* <!--text--> */}
                        <p className='side-nav-text'>احراز هویت</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='#' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaStore className='fa-solid fa-store side-nav-item-icon' />

                        {/* <!--text--> */}
                        <p className='side-nav-text'>سفارش عمده</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='dashboard-support.html' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item active-side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaCommentDots className='fa-solid fa-comment-dots side-nav-item-icon' />
                        {/* <!--text--> */}
                        <p className='side-nav-text'>تیکت پشتیبانی</p>
                      </li>
                    </a>
                  </ul>
                  <div className='side-nav-down-link-wrapper'>
                    {/* <!--link--> */}
                    <a href='./index-2.html' className='side-nav-link-v2'>
                      بازگشت به فروشگاه
                    </a>
                  </div>
                </div>
                {/*  <!--dashboard-content--> */}
                <div className='dashboard-content'>
                  {/* <!--dashbord-top--> */}
                  <div className='dashboard-top-wrapper mini-section-box-style'>
                    {/* <!--right-side--> */}
                    <div className='dashboard-top-right-wrapper'>
                      {/* <!--link--> */}
                      <a
                        href='#'
                        className='dashboard-top-link dashboard-top-link-mobile'
                      >
                        {/* <!--icon--> */}
                        <IoMdExit className='fa-solid fa-right-from-bracket' />
                        خروج
                      </a>
                      {/* <!--lable--> */}
                      <label
                        for='side-nav-input-v1'
                        className='side-nav-open-label'
                      >
                        پنل کاربری
                      </label>
                    </div>
                    {/* <!--left-side--> */}
                    <div className='dashboard-top-left-wrapper'>
                      {/* <!--text-box--> */}
                      <div className='dashboard-left-text-box'>
                        {/* <!--link--> */}
                        <a href='#' className='dashboard-top-link'>
                          {/* <!--icon--> */}
                          <FaRegCommentDots className='fa-regular fa-comment-dots' />
                          پشتیبانی
                        </a>
                        <p className='dashboard-top-text'>
                          {/* <!--text--> */}
                          <span className='dashboard-top-text-span'>
                            ۰۹۱۶۰۲۶۵۶۶۱
                          </span>
                          {/* <!--line--> */}
                          <span className='dashboard-top-text-line-span'></span>
                          {/* <!--text--> */}
                          <span className='dashboard-top-text-span'>
                            محمدرضا عبادی
                          </span>
                        </p>
                      </div>
                      {/* <!--logo--> */}
                      <div className='dashboard-top-logo-wrapper'>
                        {/* <!--logo--> */}
                        <img
                          src={logo}
                          alt='img'
                          className='dashboard-log-img'
                        />
                      </div>
                    </div>
                  </div>
                  {/* <!--==dashboard-list=--> */}
                  <div class='dashboard-list-wrapper '>
                    {/* <!--top-wrapper--> */}
                    <div class='tiket-form-top-wrapper'>
                      {/* <!--link--> */}
                      <p class='tiket-top-text'>کد تیکت :۱۰۲۲۵۹</p>
                      {/* <!--link--> */}
                      <a
                        href='dashboard-support-list.html'
                        class='tiket-form-top-link'
                      >
                        بازگشت به مرحله قبل
                      </a>
                    </div>
                    {/* <!--tiket-section--> */}
                    <section class='tiket-section mini-section-box-style'>
                      {/* <!--tiket-slider--> */}
                      <div class='tiket-slider-y'>
                        {/* <!--tiket-content--> */}
                        <div class='tiket-content'>
                          {/* <!--tiket--> */}
                          <div class='user-tiket'>
                            {/* <!--tiket-profile--> */}
                            <div class='tiket-profile-wrapper'>
                              {/* <!--profile-img--> */}
                              <div class='tiket-profile-img'>
                                {/* <!--img--> */}
                                <img
                                  src='./assets/images/tiket-profile-img.png'
                                  alt='img'
                                  class='tiket-profile-img-content'
                                />
                              </div>
                              {/* <!--tiket-profile-info--> */}
                              <div class='tiket-profile-info'>
                                {/* <!--text--> */}
                                <p class='tiket-text'>محمدرضا عبادی</p>
                                {/* <!--text--> */}
                                <p class='tiket-sub-text'>۰۹۱۶۰۲۶۵۶۶۱</p>
                              </div>
                            </div>
                            {/* <!--tiket-text--> */}
                            <div class='tiket-text-box'>
                              {/* <!--title--> */}
                              <h3 class='tiket-text-title'>عنوان تیکت</h3>
                              {/* <!--tiket-text--> */}
                              <p class='tiket-text tiket-text-v2'>
                                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                                صنعت چاپ، و با استفاده از طراحان گرافیک است،
                                چاپگرها و متون بلکه روزنامه و مجله در ستون و
                                سطرآنچنان که لازم است، و برای شرایط فعلی
                                تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف
                                بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در
                                شصت و سه درصد گذشته حال و آینده، شناخت فراوان
                                جامعه و متخصصان را می طلبد، تا با نرم افزارها
                                شناخت بیشتری را برای طراحان رایانه ای علی الخصوص
                                طراحان
                              </p>
                            </div>
                            {/* <!--sub-text--> */}
                            <p class='tiket-sub-text'>1402-08-24 13:28</p>
                          </div>
                          {/* <!--tiket-answer--> */}
                          <div class='tiket-answer'>
                            {/* <!--tiket-profile--> */}
                            <div class='tiket-profile-wrapper tiket-profile-wrapper-answer'>
                              {/* <!--profile-img--> */}
                              <div class='tiket-profile-img'>
                                {/* <!--img--> */}
                                <img
                                  src='./assets/images/tiket-profile-img.png'
                                  alt='img'
                                  class='tiket-profile-img-content'
                                />
                              </div>
                              {/* <!--tiket-profile-info--> */}
                              <div class='tiket-profile-info tiekt-profile-info-v2'>
                                {/* <!--text--> */}
                                <p class='tiket-text'>محمدرضا عبادی</p>
                                {/* <!--text--> */}
                                <p class='tiket-sub-text'>کارشناسی فنی فروش</p>
                              </div>
                            </div>
                            {/* <!--answer-box--> */}
                            <div class='tiket-answer-text-box'>
                              {/* <!--text--> */}
                              <p class='tiket-text'>
                                <span class='tiket-text-span'>
                                  با سلام وقت بخیر
                                </span>
                                احتراما;
                              </p>
                              {/* <!--under-line--> */}
                              <span class='under-line-v1'></span>
                              {/* <!--text--> */}
                              <p class='tiket-text tiket-text-v2'>
                                مشکل شما با موفقیت حل شد
                              </p>
                              {/* <!--under-line--> */}
                              <span class='under-line-v1'></span>
                              {/* <!--text--> */}
                              <p class='tiket-text tiket-text-v2'>
                                با تشکرپشتیبانی فروشگاه زود گیفت
                              </p>
                            </div>
                            {/* <!--sub-text--> */}
                            <p class='tiket-sub-text tiket-sub-text-v2'>
                              1402-08-24 13:28
                            </p>
                          </div>
                          {/* <!--tiket--> */}
                          <div class='user-tiket'>
                            {/* <!--tiket-profile--> */}
                            <div class='tiket-profile-wrapper'>
                              {/* <!--profile-img--> */}
                              <div class='tiket-profile-img'>
                                {/* <!--img--> */}
                                <img
                                  src='./assets/images/tiket-profile-img.png'
                                  alt='img'
                                  class='tiket-profile-img-content'
                                />
                              </div>
                              {/* <!--tiket-profile-info--> */}
                              <div class='tiket-profile-info'>
                                {/* <!--text--> */}
                                <p class='tiket-text'>محمدرضا عبادی</p>
                                {/* <!--text--> */}
                                <p class='tiket-sub-text'>۰۹۱۶۰۲۶۵۶۶۱</p>
                              </div>
                            </div>
                            {/* <!--tiket-text--> */}
                            <div class='tiket-text-box'>
                              {/* <!--title--> */}
                              <h3 class='tiket-text-title'>عنوان تیکت</h3>
                              {/* <!--tiket-text--> */}
                              <p class='tiket-text tiket-text-v2'>
                                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از
                                صنعت چاپ، و با استفاده از طراحان گرافیک است،
                                چاپگرها و متون بلکه روزنامه و مجله در ستون و
                                سطرآنچنان که لازم است، و برای شرایط فعلی
                                تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف
                                بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در
                                شصت و سه درصد گذشته حال و آینده، شناخت فراوان
                                جامعه و متخصصان را می طلبد، تا با نرم افزارها
                                شناخت بیشتری را برای طراحان رایانه ای علی الخصوص
                                طراحان
                              </p>
                            </div>
                            {/* <!--sub-text--> */}
                            <p class='tiket-sub-text'>1402-08-24 13:28</p>
                          </div>
                          {/* <!--tiket-answer--> */}
                          <div class='tiket-answer'>
                            {/* <!--tiket-profile--> */}
                            <div class='tiket-profile-wrapper tiket-profile-wrapper-answer'>
                              {/* <!--profile-img--> */}
                              <div class='tiket-profile-img'>
                                {/* <!--img--> */}
                                <img
                                  src='./assets/images/tiket-profile-img.png'
                                  alt='img'
                                  class='tiket-profile-img-content'
                                />
                              </div>
                              {/* <!--tiket-profile-info--> */}
                              <div class='tiket-profile-info tiekt-profile-info-v2'>
                                {/* <!--text--> */}
                                <p class='tiket-text'>محمدرضا عبادی</p>
                                {/* <!--text--> */}
                                <p class='tiket-sub-text'>کارشناسی فنی فروش</p>
                              </div>
                            </div>
                            {/* <!--answer-box--> */}
                            <div class='tiket-answer-text-box'>
                              {/* <!--text--> */}
                              <p class='tiket-text'>
                                <span class='tiket-text-span'>
                                  با سلام وقت بخیر
                                </span>
                                احتراما;
                              </p>
                              {/* <!--under-line--> */}
                              <span class='under-line-v1'></span>
                              {/* <!--text--> */}
                              <p class='tiket-text tiket-text-v2'>
                                مشکل شما با موفقیت حل شد
                              </p>
                              {/* <!--under-line--> */}
                              <span class='under-line-v1'></span>
                              {/* <!--text--> */}
                              <p class='tiket-text tiket-text-v2'>
                                با تشکرپشتیبانی فروشگاه زود گیفت
                              </p>
                            </div>
                            {/* <!--sub-text--> */}
                            <p class='tiket-sub-text tiket-sub-text-v2'>
                              1402-08-24 13:28
                            </p>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <ToastContainer toastClassName='toast-right' />
    </div>
  )
}

export default SupportTicketDetail
