import React from 'react'
import 'react-toastify/dist/ReactToastify.css'
import '../assets/css/style.css'
import '../assets/css/fonts.css'
import '../assets/css/media-query.css'
import '../assets/css/variables.css'

import sheild from '../assets/images/shield-img.png'
import vector from '../assets/images/Vector.png'

function license() {
  return (
    <div className='body-white-color-2'>
      <main className='main'>
        <section className='license-section'>
          <div className='container'>
            <div className='license-wrapper'>
              {/* <!--input-for-controlling-the-functionality--> */}
              <input
                type='radio'
                className='license-input'
                id='license-radio-1'
                name='license-radio-input'
                hidden
                checked
              />
              {/* <!--input-for-controlling-the-functionality--> */}
              <input
                type='radio'
                className='license-input'
                id='license-radio-2'
                name='license-radio-input'
                hidden
              />
              {/* <!--input-for-controlling-the-functionality--> */}
              <input
                type='radio'
                className='license-input'
                id='license-radio-3'
                name='license-radio-input'
                hidden
              />
              {/* <!--input-for-controlling-the-functionality--> */}
              <input
                type='radio'
                className='license-input'
                id='license-radio-4'
                name='license-radio-input'
                hidden
              />
              {/* <!--input-for-controlling-the-functionality--> */}
              <input
                type='radio'
                className='license-input'
                id='license-radio-5'
                name='license-radio-input'
                hidden
              ></input>

              {/* <!--right--> */}
              <div className='license-right-wrapper'>
                {/* <!--title-image--> */}
                <div className='title-img-wrapper'>
                  {/* <!--vector-img--> */}
                  <img
                    src={vector}
                    alt='img'
                    className='title-background-img'
                  />
                  {/* <!--img--> */}
                  <img src={sheild} alt='img' className='title-img' />
                </div>
                {/* <!--title--> */}
                <h2 className='license-labels-title'>قوانین سایت</h2>
                {/* <!--license-right-content-wrapper--> */}
                <div className='license-right-content-wrapper'>
                  {/* <!--labelse-box--> */}
                  <div className='license-labels-box'>
                    {/* <!--label--> */}
                    <label
                      for='license-radio-1'
                      className='license-label license-label-1'
                    >
                      {/* <!--text--> */}
                      <p className='license-label-text'>
                        پشتیبانی فروشگاه گامالوپ:
                      </p>
                      {/* <!--icon--> */}
                      <i className='fa-regular fa-square-caret-left'></i>
                    </label>
                    {/* <!--label--> */}
                    <label
                      for='license-radio-2'
                      className='license-label license-label-2'
                    >
                      {/* <!--text--> */}
                      <p className='license-label-text'>
                        پشتیبانی فروشگاه گامالوپ:
                      </p>
                      {/* <!--icon--> */}
                      <i className='fa-regular fa-square-caret-left'></i>
                    </label>
                    {/* <!--label--> */}
                    <label
                      for='license-radio-3'
                      className='license-label license-label-3'
                    >
                      {/* <!--text--> */}
                      <p className='license-label-text'>
                        پشتیبانی فروشگاه گامالوپ:
                      </p>
                      {/* <!--icon--> */}
                      <i className='fa-regular fa-square-caret-left'></i>
                    </label>
                    {/* <!--label--> */}
                    <label
                      for='license-radio-4'
                      className='license-label license-label-4'
                    >
                      {/* <!--text--> */}
                      <p className='license-label-text'>
                        پشتیبانی فروشگاه گامالوپ:
                      </p>
                      {/* <!--icon--> */}
                      <i className='fa-regular fa-square-caret-left'></i>
                    </label>
                    {/* <!--label--> */}
                    <label
                      for='license-radio-5'
                      className='license-label license-label-5'
                    >
                      {/* <!--text--> */}
                      <p className='license-label-text'>
                        پشتیبانی فروشگاه گامالوپ:
                      </p>
                      {/* <!--icon--> */}
                      <i className='fa-regular fa-square-caret-left'></i>
                    </label>
                  </div>
                  {/* <!--contact-us-banner--> */}
                  <div className='contact-us-banner'>
                    {/* <!--text--> */}
                    <p className='contact-us-text'>
                      بنر تماس با ما قرار میگیره
                    </p>
                  </div>
                </div>
              </div>

              {/* <!--left--> */}
              <div class='license-left-wrapper'>
                {/* <!--title-wrapper--> */}
                <div class='license-left-title-wrapper'>
                  {/* <!--title--> */}
                  <p class='license-label-content-title'>قوانین</p>
                  {/* <!--line--> */}
                  <span class='license-title-line'></span>
                </div>
                {/* <!--content-wrapper--> */}
                <div class='license-lable-content license-label-content-1'>
                  {/* <!--text--> */}
                  <p class='paragraph'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                    تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                    کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                    آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم
                    افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص
                    طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این
                    صورت می توان امید داشت که تمام و دشواری موجود در ارائه
                    راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل
                    حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای
                    موجود طراحی اساسا مورد استفاده قرار گیرد. لورم ایپسوم متن
                    ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از
                    طراحان گرافیک است. چاپ جوابگوی سوالات پیوسته اهل دنیای موجود
                    طراحی اساسا مورد استفاده قرار گیرد.
                  </p>

                  {/* <!--text--> */}
                  <p class='paragraph'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                    تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                    کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                    آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم
                    افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص
                    طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این
                    صورت می توان امید داشت که تمام و دشواری موجود در ارائه
                    راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل
                    حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای
                    موجود طراحی اساسا مورد استفاده قرار گیرد. لورم ایپسوم متن
                    ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از
                    طراحان گرافیک است. چاپ جوابگوی سوالات پیوسته اهل دنیای موجود
                    طراحی اساسا مورد استفاده قرار گیرد.
                  </p>

                  {/* <!--text--> */}
                  <p class='paragraph'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                    تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                    کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                    آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم
                    افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص
                    طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این
                    صورت می توان امید داشت که تمام و دشواری موجود در ارائه
                    راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل
                    حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای
                    موجود طراحی اساسا مورد استفاده قرار گیرد. لورم ایپسوم متن
                    ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از
                    طراحان گرافیک است. چاپ جوابگوی سوالات پیوسته اهل دنیای موجود
                    طراحی اساسا مورد استفاده قرار گیرد.
                  </p>

                  {/* <!--text--> */}
                  <p class='paragraph'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                    تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                    کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                    آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم
                    افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص
                    طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این
                    صورت می توان امید داشت که تمام و دشواری موجود در ارائه
                    راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل
                    حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای
                    موجود طراحی اساسا مورد استفاده قرار گیرد. لورم ایپسوم متن
                    ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از
                    طراحان گرافیک است. چاپ جوابگوی سوالات پیوسته اهل دنیای موجود
                    طراحی اساسا مورد استفاده قرار گیرد.
                  </p>
                </div>
                {/* <!--content-wrapper--> */}
                <div class='license-lable-content license-label-content-2'>
                  {/* <!--text--> */}
                  <p class='paragraph'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                    تکنولوژی مورد نیاز و کارب است. چاپ جوابگوی سوالات پیوسته اهل
                    دنیای موجود طراحی اساسا مورد استف اده قرار گیرد.تکنولوژی
                    مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می
                    باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده
                    شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها
                    شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان
                    خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این صورت می
                    توان امید داشت که تمام و دشواری موجود در ارائه راهکارها و
                    شرایط سخت تایپ به پا
                  </p>
                </div>
                {/* <!--content-wrapper--> */}
                <div class='license-lable-content license-label-content-3'>
                  {/* <!--text--> */}
                  <p class='paragraph'>
                    تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                    کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                    آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم
                    افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص
                    طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این
                    صورت می توان امید داشت که تمام و دشواری موجود در ارائه
                    راهکارها و شرایط سخت تایپ به پا
                  </p>
                </div>
                {/* <!--content-wrapper--> */}
                <div class='license-lable-content license-label-content-4'>
                  {/* <!--text--> */}
                  <p class='paragraph'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است. چاپگرها و متلورم ایپسوم متن
                    ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از
                    طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در
                    ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی
                    مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی
                    می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده،
                    شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها
                    شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان
                    خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می
                    توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و
                    شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی
                    دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود
                    طراحی اساسا مورد استفاده قرار گیرد.است. چاپ جوابگوی سوالات
                    پیوسته اهل دنیای موجود طراحی اساسا مورد لورم ایپسوم متن
                    ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از
                    طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در
                    ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی
                    مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی
                    می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده،
                    شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم افزارها
                    شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان
                    خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این صورت می
                    توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و
                    شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی
                    دستاوردهای اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود
                    طراحی اساسا مورد استفاده قرار گیرد. استفاده قرار گیرد.
                  </p>
                </div>
                {/* <!--content-wrapper--> */}
                <div class='license-lable-content license-label-content-5'>
                  {/* <!--text--> */}
                  <p class='paragraph'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و
                    با استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه
                    و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی
                    تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای
                    کاربردی می باشد. کتابهای زیادی در شصت و سه درصد گذشته، حال و
                    آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم
                    افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص
                    طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد. در این
                    صورت می توان امید داشت که تمام و دشواری موجود در ارائه
                    راهکارها و شرایط سخت تایپ به پایان رسد وزمان مورد نیاز شامل
                    حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای
                    موجود طراحی اساسا مورد استفاده قرار گیرد. لورم ایپسوم متن
                    ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از
                    طراحان گرافیک است. چاپ جوابگوی سوالات پیوسته اهل دنیای موجود
                    طراحی اساسا مورد استفاده قرار گیرد.
                  </p>
                </div>
              </div>

            </div>
          </div>
        </section>
      </main>

    </div>
  )
}

export default license
