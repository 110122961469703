import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../assets/css/style.css'
import '../assets/css/fonts.css'
import '../assets/css/media-query.css'
import '../assets/css/variables.css'

// icon
import { FaCircleXmark } from 'react-icons/fa6'
import { IoMdExit } from 'react-icons/io'
import { IoMenu } from 'react-icons/io5'
import {
  FaShoppingBasket,
  FaChartBar,
  FaQrcode,
  FaStore,
  FaCommentDots,
  FaRegCommentDots,
  FaShoppingBag,
  FaBook,
  FaArrowLeft,
  FaCaretSquareDown,
  FaEnvelopeOpenText,
  FaEnvelope,
  FaCaretSquareLeft,
  FaCaretSquareRight,
} from 'react-icons/fa'

import banner from '../assets/images/dashboard-banner-background.png'
import logo from '../assets/images/logo.png'
import lastOrder from '../assets/images/last-order-img.png'
import headphoneSvg from '../assets/images/headphone-headphones-svgrepo-com.svg'
import serviceBox from '../assets/images/service-box.png'

function Dashboard() {
  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    const accessToken = sessionStorage.getItem('access_token')
    setIsLoggedIn(!!accessToken)
  }, [])

  // خروج 

    const handleLogoutClick = (event) => {
      event.preventDefault() // جلوگیری از رفتار پیش فرض لینک
      sessionStorage.removeItem('access_token')
      setIsLoggedIn(false)
      navigate('/')
    }

  return (
    <div className='body-white-color-2'>
      <div className='page-main-contaier body-white-color-2'>
        {/* <!--=========Main============--> */}
        <main className='main'>
          {/* <!--============Dashboard-section--> */}
          <section className='dashboard-sectoin'>
            {/* <!--setting-container--> */}
            <div className='container'>
              {/* <!--dashboard-content---> */}
              <div className='dashboard-content-wrapper'>
                {/* <!--==========input-for-controlling-the-funtionality========--> */}
                <input
                  type='checkbox'
                  className='side-nav-input-v1'
                  id='side-nav-input-v1'
                  hidden
                />
                {/* <!---side-nav--> */}
                <div className='dashboard-side-nav mini-section-box-style'>
                  {/* <!--icon--> */}
                  <label
                    for='side-nav-input-v1'
                    className='side-nav-close-label'
                  >
                    {/* <!--icon--> */}
                    <FaCircleXmark className='fa-solid fa-xmark' />
                  </label>
                  {/* <!--logo--> */}
                  <div className='side-nav-logo-wrapper'>
                    {/* <!--img--> */}
                    <img src={logo} alt='img' className='side-nav-logo' />
                    {/* <!--text--> */}
                    <h4 className='side-nav-logo-text'>زود گیفت</h4>
                  </div>
                  {/* <!--list--> */}
                  <ul className='side-nav-list'>
                    {/* <!--link-item--> */}
                    <a href='/dashboard' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item active-side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}
                        <IoMenu className='fa-solid fa-table-list side-nav-item-icon' />
                        {/* <!--text--> */}
                        <p className='side-nav-text'>داشبورد</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='/dashboard/orders' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaShoppingBasket className='fa-solid fa-basket-shopping side-nav-item-icon' />

                        {/* <!--text--> */}
                        <p className='side-nav-text'>مشاهده سفارشات</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='#' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item '>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaChartBar className='fa-solid fa-chart-line side-nav-item-icon' />

                        {/* <!--text--> */}
                        <p className='side-nav-text'>تراکنش اخیر</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a
                      href='/dashboard/authentication'
                      className='side-nav-link'
                    >
                      {/* <!--item--> */}
                      <li className='side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaQrcode className='fa-solid fa-qrcode side-nav-item-icon' />
                        {/* <!--text--> */}
                        <p className='side-nav-text'>احراز هویت</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='#' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaStore className='fa-solid fa-store side-nav-item-icon' />

                        {/* <!--text--> */}
                        <p className='side-nav-text'>سفارش عمده</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='/dashboard/support' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaCommentDots className='fa-solid fa-comment-dots side-nav-item-icon' />
                        {/* <!--text--> */}
                        <p className='side-nav-text'>تیکت پشتیبانی</p>
                      </li>
                    </a>
                  </ul>
                  <div className='side-nav-down-link-wrapper'>
                    {/* <!--link--> */}
                    <a href='/' className='side-nav-link-v2'>
                      بازگشت به فروشگاه
                    </a>
                  </div>
                </div>
                {/*  <!--dashboard-content--> */}
                <div className='dashboard-content'>
                  {/* <!--dashbord-top--> */}
                  <div className='dashboard-top-wrapper mini-section-box-style'>
                    {/* <!--right-side--> */}
                    <div className='dashboard-top-right-wrapper'>
                      {/* <!--link--> */}
                      <a
                        href='#'
                        className='dashboard-top-link dashboard-top-link-mobile'
                        onClick={handleLogoutClick}
                      >
                        <IoMdExit className='fa-solid fa-right-from-bracket' />
                        خروج
                      </a>
                      {/* <!--lable--> */}
                      <label
                        for='side-nav-input-v1'
                        className='side-nav-open-label'
                      >
                        پنل کاربری
                      </label>
                    </div>
                    {/* <!--left-side--> */}
                    <div className='dashboard-top-left-wrapper'>
                      {/* <!--text-box--> */}
                      <div className='dashboard-left-text-box'>
                        {/* <!--link--> */}
                        <a href='#' className='dashboard-top-link'>
                          {/* <!--icon--> */}
                          <FaRegCommentDots className='fa-regular fa-comment-dots' />
                          پشتیبانی
                        </a>
                        <p className='dashboard-top-text'>
                          {/* <!--text--> */}
                          <span className='dashboard-top-text-span'>
                            ۰۹۱۶۰۲۶۵۶۶۱
                          </span>
                          {/* <!--line--> */}
                          <span className='dashboard-top-text-line-span'></span>
                          {/* <!--text--> */}
                          <span className='dashboard-top-text-span'>
                            محمدرضا عبادی
                          </span>
                        </p>
                      </div>
                      {/* <!--logo--> */}
                      <div className='dashboard-top-logo-wrapper'>
                        {/* <!--logo--> */}
                        <img
                          src={logo}
                          alt='img'
                          className='dashboard-log-img'
                        />
                      </div>
                    </div>
                  </div>
                  {/* <!--banners--> */}
                  <div className='dashboard-banners-link-wrapper'>
                    {/* <!--banner-link--> */}
                    <a
                      href='/dashboard/orders'
                      className='dashboard-banner-link'
                    >
                      {/* <!--banner--> */}
                      <div class='dashboard-banner-v1'>
                        <img
                          src={banner}
                          alt='img'
                          class='dashboard-banner-img'
                        />
                        {/* <!--text--> */}
                        <p className='dashboard-banner-text'>
                          {/* <!--icon--> */}
                          <i class='fa-solid fa-bag-shopping'></i>
                          <FaShoppingBag className='fa-solid fa-bag-shopping' />
                          مشاهده سفارشات
                        </p>
                      </div>
                    </a>
                    {/* <!--banner-link--> */}
                    <a
                      href='/dashboard/authentication'
                      className='dashboard-banner-link'
                    >
                      {/* <!--banner--> */}
                      <div className='dashboard-banner-v1'>
                        <img
                          src={banner}
                          alt='img'
                          className='dashboard-banner-img'
                        />
                        {/* <!--text--> */}
                        <p className='dashboard-banner-text'>
                          {/* <!--icon--> */}
                          <FaQrcode className='fa-solid fa-qrcode' />
                          احراز هویت
                        </p>
                      </div>
                    </a>
                    {/* <!--banner-link--> */}
                    <a href='#' className='dashboard-banner-link'>
                      {/* <!--banner--> */}
                      <div className='dashboard-banner-v1'>
                        <img
                          src={banner}
                          alt='img'
                          className='dashboard-banner-img'
                        />
                        {/* <!--text--> */}
                        <p className='dashboard-banner-text'>
                          {/* <!--icon--> */}
                          <FaBook className='fa-solid fa-book' />
                          مشاهده آموزش
                        </p>
                      </div>
                    </a>
                  </div>
                  {/* <!--dashboard-bottom-content--> */}
                  <div className='dashboard-content-bottom '>
                    {/* <!--last-orders--> */}
                    <div className='last-orders-wrapper mini-section-box-style'>
                      {/* <!--last-order-top---> */}
                      <div className='dashboard-title-wrapper'>
                        {/* <!--title--> */}
                        <h4 className='h4-dashboard-title'>
                          {/* <!--icon--> */}
                          <FaChartBar className='fa-solid fa-chart-line' />
                          تراکنش اخیر
                        </h4>
                        {/* <!--see-more-line--> */}
                        <a href='#' className='dashboard-see-more-link'>
                          مشاهدهمه
                          {/* <!--icon--> */}
                          <FaArrowLeft className='fa-solid fa-arrow-left' />
                        </a>
                      </div>
                      {/* <!--orders-titles--> */}
                      <div className='last-order-title-wrapper'>
                        {/* <!--title--> */}
                        <h5 className='h5-dashboard-title'>شماره سفارش</h5>
                        {/* <!--title--> */}
                        <h5 className='h5-dashboard-title'>محصول</h5>
                        {/* <!--title--> */}
                        <h5 className='h5-dashboard-title'>جزئیات سفارش</h5>
                      </div>
                      {/* <!--line--> */}
                      <span className='under-line-v1'></span>
                      {/* <!--order-item--> */}
                      <div className='last-order-item'>
                        {/* <!--text--> */}
                        <p className='last-order-text-v1'>zg-۰۰۰۱</p>
                        {/* <!--product-box--> */}
                        <div className='last-order-product-wrapper'>
                          {/* <!--img--> */}
                          <img
                            src={lastOrder}
                            alt='img'
                            className='last-order-img'
                          />
                          {/* <!--text--> */}
                          <p className='last-order-text-v2'>
                            لایسنس اورجینال ادوبی فتوشاپ سه ماهه
                          </p>
                        </div>
                        {/* <!--label--> */}
                        <label
                          for='#'
                          className='last-order-label last-order-text-v1'
                        >
                          مشاهده جزئیات
                          {/* <!--icon--> */}
                          <FaCaretSquareDown className='fa-regular fa-square-caret-down' />
                        </label>
                      </div>
                      {/* <!--order-item--> */}
                      <div className='last-order-item'>
                        {/* <!--text--> */}
                        <p className='last-order-text-v1'>zg-۰۰۰۱</p>
                        {/* <!--product-box--> */}
                        <div className='last-order-product-wrapper'>
                          {/* <!--img--> */}
                          <img
                            src={lastOrder}
                            alt='img'
                            className='last-order-img'
                          />
                          {/* <!--text--> */}
                          <p className='last-order-text-v2'>
                            لایسنس اورجینال ادوبی فتوشاپ سه ماهه
                          </p>
                        </div>
                        {/* <!--label--> */}
                        <label
                          for='#'
                          className='last-order-label last-order-text-v1'
                        >
                          مشاهده جزئیات
                          {/* <!--icon--> */}
                          <FaCaretSquareDown className='fa-regular fa-square-caret-down' />
                        </label>
                      </div>
                      {/* <!--order-item--> */}
                      <div className='last-order-item'>
                        {/* <!--text--> */}
                        <p className='last-order-text-v1'>zg-۰۰۰۱</p>
                        {/* <!--product-box--> */}
                        <div className='last-order-product-wrapper'>
                          {/* <!--img--> */}
                          <img
                            src={lastOrder}
                            alt='img'
                            className='last-order-img'
                          />
                          {/* <!--text--> */}
                          <p className='last-order-text-v2'>
                            لایسنس اورجینال ادوبی فتوشاپ سه ماهه
                          </p>
                        </div>
                        {/* <!--label--> */}
                        <label
                          for='#'
                          className='last-order-label last-order-text-v1'
                        >
                          مشاهده جزئیات
                          {/* <!--icon--> */}
                          <FaCaretSquareDown className='fa-regular fa-square-caret-down' />
                        </label>
                      </div>
                    </div>
                    {/* <!--support-box--> */}
                    <div className='support-box mini-section-box-style'>
                      {/* <!--support-top---> */}
                      <div className='dashboard-title-wrapper'>
                        {/* <!--title--> */}
                        <h4 className='h4-dashboard-title'>
                          {/* <!--icon--> */}
                          <FaCommentDots className='fa-regular fa-comment-dots' />
                          تیکت پشتیبانی
                        </h4>
                        {/* <!--see-more-line--> */}
                        <a
                          href='/dashboard/support'
                          className='dashboard-see-more-link'
                        >
                          مشاهدهمه
                          {/* <!--icon--> */}
                          <FaArrowLeft className='fa-solid fa-arrow-left' />
                        </a>
                      </div>
                      {/* <!--support-img--> */}
                      <img
                        src={headphoneSvg}
                        alt='img'
                        className='support-banner-img'
                      />
                      {/* <!--support-bottom-wrapper--> */}
                      <div className='support-bottom-wrapper'>
                        {/* <!--link--> */}
                        <a href='#' className='dashboard-top-link'>
                          {/* <!--icon--> */}
                          <FaEnvelopeOpenText className='fa-regular fa-envelope-open' />
                          در حال بررسی
                        </a>
                        {/* <!--link--> */}
                        <a href='#' className='dashboard-top-link'>
                          {/* <!--icon--> */}
                          <FaEnvelope className='fa-regular fa-envelope' />
                          پاسخ داده شده
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <!--services--> */}
                  <div className='services-wrapper mini-section-box-style'>
                    {/* <!--titile-wrapper--> */}
                    <div className='dashboard-title-wrapper service-title-wrapper'>
                      {/* <!--title--> */}
                      <h4 className='h4-dashboard-title'>
                        لیست خدمات زود گیفت
                      </h4>
                      {/* <!--slider-buttons-wrapper--> */}
                      <div className='slider-buttons-wrapper-v2'>
                        {/* <!--button--> */}
                        <button className='slider-btn-v2'>
                          {/* <!--icon--> */}

                          <FaCaretSquareRight className='fa-regular fa-square-caret-right' />
                        </button>
                        {/* <!--button--> */}
                        <button className='slider-btn-v2'>
                          {/* <!--icon--> */}

                          <FaCaretSquareLeft className='fa-regular fa-square-caret-left' />
                        </button>
                      </div>
                    </div>

                    {/* <!--setting-slider-wrapper--> */}
                    <div className='service-slider-x-wrapper"'>
                      {/* <!--slider--> */}
                      <div className='slider-x service-slider slider-width-100'>
                        {/* <!--link--> */}
                        <a href='#' className='service-link'>
                          {/* <!--box--> */}
                          <div className='service-box'>
                            {/* <!--img--> */}
                            <img src={serviceBox} alt='img' />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <ToastContainer toastClassName='toast-right' />
    </div>
  )
}

export default Dashboard
