import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import '../assets/css/style.css'
import '../assets/css/fonts.css'
import '../assets/css/media-query.css'
import '../assets/css/variables.css'

// icon
import {
  FaCircleXmark,
  FaPenToSquare,
  FaTriangleExclamation,
} from 'react-icons/fa6'
import { IoMdExit } from 'react-icons/io'
import { IoMenu } from 'react-icons/io5'
import {
  FaShoppingBasket,
  FaChartBar,
  FaQrcode,
  FaStore,
  FaCommentDots,
  FaRegCommentDots,
  FaCloudDownloadAlt,
} from 'react-icons/fa'

import logo from '../assets/images/logo.png'
import finger from '../assets/images/finger-pattern.png'

function Authentication() {
  const navigate = useNavigate()

  // detail

    const [profileData, setProfileData] = useState({
      first_name: '',
      phone: '',
      email: '',
      meli_number: '',
    })

      useEffect(() => {
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: 'https://api.zoodgift.com/api/profile',
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
          },
        }

        axios
          .request(config)
          .then((response) => {
            const data = response.data.data
            setProfileData({
              first_name: data.first_name,
              phone: data.phone,
              email: data.email,
              meli_number: data.meli_number,
            })
          })
          .catch((error) => {
            console.log(error)
          })
      }, [])

  return (
    <div className='body-white-color-2'>
      <div className='page-main-contaier body-white-color-2'>
        {/* <!--=========Main============--> */}
        <main className='main'>
          {/* <!--============Dashboard-section--> */}
          <section className='dashboard-sectoin'>
            {/* <!--setting-container--> */}
            <div className='container'>
              {/* <!--dashboard-content---> */}
              <div className='dashboard-content-wrapper'>
                {/* <!--==========input-for-controlling-the-funtionality========--> */}
                <input
                  type='checkbox'
                  className='side-nav-input-v1'
                  id='side-nav-input-v1'
                  hidden
                />
                {/* <!---side-nav--> */}
                <div className='dashboard-side-nav mini-section-box-style'>
                  {/* <!--icon--> */}
                  <label
                    for='side-nav-input-v1'
                    className='side-nav-close-label'
                  >
                    {/* <!--icon--> */}
                    <FaCircleXmark className='fa-solid fa-xmark' />
                  </label>
                  {/* <!--logo--> */}
                  <div className='side-nav-logo-wrapper'>
                    {/* <!--img--> */}
                    <img src={logo} alt='img' className='side-nav-logo' />
                    {/* <!--text--> */}
                    <h4 className='side-nav-logo-text'>زود گیفت</h4>
                  </div>
                  {/* <!--list--> */}
                  <ul className='side-nav-list'>
                    {/* <!--link-item--> */}
                    <a href='/dashboard' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <IoMenu className='fa-solid fa-table-list side-nav-item-icon' />

                        {/* <!--text--> */}
                        <p className='side-nav-text'>داشبورد</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='/dashboard/orders' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaShoppingBasket className='fa-solid fa-basket-shopping side-nav-item-icon' />

                        {/* <!--text--> */}
                        <p className='side-nav-text'>مشاهده سفارشات</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='#' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item '>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaChartBar className='fa-solid fa-chart-line side-nav-item-icon' />

                        {/* <!--text--> */}
                        <p className='side-nav-text'>تراکنش اخیر</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a
                      href='/dashboard/authentication'
                      className='side-nav-link'
                    >
                      {/* <!--item--> */}
                      <li className='side-nav-item active-side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}
                        <FaQrcode className='fa-solid fa-qrcode side-nav-item-icon' />
                        {/* <!--text--> */}
                        <p className='side-nav-text'>احراز هویت</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='#' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaStore className='fa-solid fa-store side-nav-item-icon' />

                        {/* <!--text--> */}
                        <p className='side-nav-text'>سفارش عمده</p>
                      </li>
                    </a>
                    {/* <!--link-item--> */}
                    <a href='/dashboard/support' className='side-nav-link'>
                      {/* <!--item--> */}
                      <li className='side-nav-item'>
                        {/* <!--side-line--> */}
                        <span className='side-nav-span'></span>
                        {/* <!--icon--> */}

                        <FaCommentDots className='fa-solid fa-comment-dots side-nav-item-icon' />
                        {/* <!--text--> */}
                        <p className='side-nav-text'>تیکت پشتیبانی</p>
                      </li>
                    </a>
                  </ul>
                  <div className='side-nav-down-link-wrapper'>
                    {/* <!--link--> */}
                    <a href='/' className='side-nav-link-v2'>
                      بازگشت به فروشگاه
                    </a>
                  </div>
                </div>

                {/*  <!--dashboard-content--> */}
                <div className='dashboard-content'>
                  {/* <!--dashbord-top--> */}
                  <div className='dashboard-top-wrapper mini-section-box-style'>
                    {/* <!--right-side--> */}
                    <div className='dashboard-top-right-wrapper'>
                      {/* <!--link--> */}
                      <a
                        href='#'
                        className='dashboard-top-link dashboard-top-link-mobile'
                      >
                        {/* <!--icon--> */}
                        <IoMdExit className='fa-solid fa-right-from-bracket' />
                        خروج
                      </a>
                      {/* <!--lable--> */}
                      <label
                        for='side-nav-input-v1'
                        className='side-nav-open-label'
                      >
                        پنل کاربری
                      </label>
                    </div>
                    {/* <!--left-side--> */}
                    <div className='dashboard-top-left-wrapper'>
                      {/* <!--text-box--> */}
                      <div className='dashboard-left-text-box'>
                        {/* <!--link--> */}
                        <a href='#' className='dashboard-top-link'>
                          {/* <!--icon--> */}
                          <FaRegCommentDots className='fa-regular fa-comment-dots' />
                          پشتیبانی
                        </a>
                        <p className='dashboard-top-text'>
                          {/* <!--text--> */}
                          <span className='dashboard-top-text-span'>
                            ۰۹۱۶۰۲۶۵۶۶۱
                          </span>
                          {/* <!--line--> */}
                          <span className='dashboard-top-text-line-span'></span>
                          {/* <!--text--> */}
                          <span className='dashboard-top-text-span'>
                            محمدرضا عبادی
                          </span>
                        </p>
                      </div>
                      {/* <!--logo--> */}
                      <div className='dashboard-top-logo-wrapper'>
                        {/* <!--logo--> */}
                        <img
                          src={logo}
                          alt='img'
                          className='dashboard-log-img'
                        />
                      </div>
                    </div>
                  </div>
                  {/* <!--form--> */}
                  <form className='form-v4 mini-section-box-style' action='#'>
                    {/* <!--right-side--> */}
                    <div className='form-v4-right-side'>
                      {/* <!--form-group-wrapper--> */}
                      <div className='form-group-wrapper-v4'>
                        {/* <!--title--> */}
                        <h6 className='form-v4-title'>اطلاعات هویتی :</h6>
                        {/* <!--form-group--> */}
                        <div className='form-group-v4'>
                          {/* <!--label--> */}
                          <label
                            for='form-v4-input-1'
                            className='form-v4-label'
                          >
                            نام و نام خانوادگی
                          </label>
                          {/* <!--input--> */}
                          <input
                            type='text'
                            name='name'
                            id='form-v4-input-1'
                            className='form-v4-input'
                            value={profileData.first_name}
                            readOnly
                          />
                          {/* <!--btn--> */}
                          <label
                            for='name-family-name-input'
                            className='from-v4-edite-btn authentication-label'
                          >
                            {/* <!--icon--> */}
                            <FaPenToSquare className='fa-solid fa-pen-to-square form-v4-edite-icon' />
                          </label>
                        </div>
                        {/* <!--form-group--> */}
                        <div className='form-group-v4'>
                          {/* <!--label--> */}
                          <label
                            for='form-v4-input-2'
                            className='form-v4-label'
                          >
                            شماره موبایل
                          </label>
                          {/* <!--input--> */}
                          <input
                            type='text'
                            name='phone'
                            id='form-v4-input-2'
                            className='form-v4-input'
                            value={profileData.phone}
                            readOnly
                          />
                          {/* <!--btn--> */}
                          <label
                            for='pop-up-number-input'
                            className='from-v4-edite-btn'
                          >
                            {/* <!--icon--> */}

                            <FaPenToSquare className='fa-solid fa-pen-to-square form-v4-edite-icon' />
                          </label>
                        </div>
                      </div>
                      {/* <!--form-group-wrapper--> */}
                      <div className='form-group-wrapper-v4'>
                        {/* <!--title--> */}
                        <h6 className='form-v4-title'>تغییر رمز عبور:</h6>
                        {/* <!--form-group--> */}
                        <div className='form-group-v4 form-group-v4-2'>
                          {/* <!--input--> */}
                          <input
                            type='password'
                            name='password'
                            id='form-v4-input-1'
                            className='form-v4-input-2'
                            placeholder='رمز عبور جدید'
                          />
                        </div>
                        {/* <!--form-group--> */}
                        <div className='form-group-v4 form-group-v4-2'>
                          {/* <!--input--> */}
                          <input
                            type='password'
                            name='password'
                            id='form-v4-input-2'
                            className='form-v4-input-2'
                            placeholder='تکرار رمز عبور جدید'
                          />
                          {/* <!--button--> */}
                          <button className='form-group-v4-btn-2'>
                            ویرایش
                            <img
                              src={finger}
                              alt='img'
                              className='form-group-v4-btn-2-icon'
                            />
                          </button>
                        </div>
                        {/* <!--wraning--> */}
                        <div className='warning-box warning-box-v3 dashboard-wraning-box'>
                          {/* <!--title--> */}
                          <h6 className='warning-box-title'>
                            رمز عبور شما باید حداقل ۸ حرف باشد.
                          </h6>
                          {/* <!--list--> */}
                          <ul className='warning-box-list'>
                            {/* <!--item--> */}
                            <li className='warning-box-item'>شامل عدد</li>
                            {/* <!--item--> */}
                            <li className='warning-box-item'>حداقل ۸ حرف</li>
                            {/* <!--item--> */}
                            <li className='warning-box-item'>
                              شامل علامت (!@#$%&*^)
                            </li>
                            {/* <!--item--> */}
                            <li className='warning-box-item'>
                              شامل یک حرف بزرگ و کوچک
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <!--left-side--> */}
                    <div className='form-v4-left-side'>
                      {/* <!--form-group-wrapper--> */}
                      <div className='form-group-wrapper-v4'>
                        {/* <!--title--> */}
                        <h6 className='form-v4-title'>&nbsp;</h6>
                        {/* <!--form-group--> */}
                        <div className='form-group-v4'>
                          {/* <!--label--> */}
                          <label
                            for='form-v4-input-5'
                            className='form-v4-label'
                          >
                            ایمیل
                          </label>
                          {/* <!--input--> */}
                          <input
                            type='email'
                            name='email'
                            id='form-v4-input-5'
                            className='form-v4-input'
                            value={profileData.email}
                            readOnly
                          />
                          {/* <!--btn--> */}
                          <label
                            for='email-pop-up-input'
                            className='from-v4-edite-btn'
                          >
                            {/* <!--icon--> */}

                            <FaPenToSquare className='fa-solid fa-pen-to-square form-v4-edite-icon' />
                          </label>
                        </div>
                        {/* <!--form-group--> */}
                        <div className='form-group-v4'>
                          {/* <!--label--> */}
                          <label
                            for='form-v4-input-6'
                            className='form-v4-label'
                          >
                            کد ملی
                          </label>
                          {/* <!--input--> */}
                          <input
                            type='text'
                            name='meli_number'
                            id='form-v4-input-6'
                            className='form-v4-input'
                            value={profileData.meli_number}
                            readOnly
                          />
                          {/* <!--btn--> */}
                          <label
                            for='pop-up-id-number-input'
                            className='from-v4-edite-btn'
                          >
                            {/* <!--icon--> */}

                            <FaPenToSquare className='fa-solid fa-pen-to-square form-v4-edite-icon' />
                          </label>
                        </div>
                      </div>
                      {/* <!--form-group-wrapper--> */}
                      <div className='form-group-wrapper-v4'>
                        {/* <!--title--> */}
                        <h6 className='form-v4-title'>ثبت شبکه اجتماعی :</h6>
                        {/* <!--form-group--> */}
                        <div className='form-group-v4'>
                          {/* <!--label--> */}
                          <label
                            for='form-v4-input-7'
                            className='form-v4-label'
                          >
                            تلگرام
                          </label>
                          {/* <!--input--> */}
                          <input
                            type='email'
                            name='email'
                            id='form-v4-input-7'
                            className='form-v4-input'
                            value='@gamaloop'
                          />
                          {/* <!--btn--> */}
                          <label
                            for='pop-up-telegram-input'
                            className='from-v4-edite-btn'
                          >
                            {/* <!--icon--> */}

                            <FaPenToSquare className='fa-solid fa-pen-to-square form-v4-edite-icon' />
                          </label>
                        </div>
                        {/* <!--form-group--> */}
                        <div className='form-group-v4'>
                          {/* <!--label--> */}
                          <label
                            for='form-v4-input-8'
                            className='form-v4-label'
                          >
                            اینستاگرام
                          </label>
                          {/* <!--input--> */}
                          <input
                            type='text'
                            name='name'
                            id='form-v4-input-8'
                            className='form-v4-input'
                            value='@gama_loop'
                          />
                          {/* <!--btn--> */}
                          <label
                            for='pop-up-instagram-input'
                            className='from-v4-edite-btn'
                          >
                            {/* <!--icon--> */}

                            <FaPenToSquare className='fa-solid fa-pen-to-square form-v4-edite-icon' />
                          </label>
                        </div>
                        {/* <!--upload-box-- */}
                        <div className='form-v4-upload-box'>
                          {/* <!--input-wrapper--> */}
                          <input
                            type='file'
                            className='form-v4-upload-input'
                            id='form-v4-upload-input'
                            hidden
                          />
                          {/* <!--label--> */}
                          <label
                            for='form-v4-upload-input'
                            className='form-v4-uload-label'
                          >
                            {/* <!--icon--> */}

                            <FaCloudDownloadAlt className='fa-solid fa-cloud-arrow-up' />
                          </label>
                          {/* <!--title--> */}
                          <h6 className='form-v4-title'>
                            آپلود تصویر پروفایل :
                          </h6>
                          {/* <!--form-upload-warning--> */}
                          <div className='form-v4-upload-warning-box'>
                            {/* <!--icon--> */}

                            <FaTriangleExclamation className='fa-solid fa-triangle-exclamation warning-icon-v4' />

                            {/* <!--text--> */}
                            <p className='warning-text-v4'>
                              حجم تصویر ارسالی می بایست کمتر از 1 مگابایت باشد
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  {/* <!--input-for-controlling-the-funtionality--> */}
                  <input
                    type='checkbox'
                    id='name-family-name-input'
                    className='authentication-input'
                    hidden
                  />
                  {/* <!--input-for-controlling-the-funtionality--> */}
                  <input
                    type='checkbox'
                    id='pop-up-number-input'
                    className='authentication-input'
                    hidden
                  />
                  {/* <!--input-for-controlling-the-funtionality--> */}
                  <input
                    type='checkbox'
                    id='email-pop-up-input'
                    className='authentication-input'
                    hidden
                  />
                  {/* <!--input-for-controlling-the-funtionality--> */}
                  <input
                    type='checkbox'
                    id='pop-up-id-number-input'
                    className='authentication-input'
                    hidden
                  />
                  {/* <!--input-for-controlling-the-funtionality--> */}
                  <input
                    type='checkbox'
                    id='pop-up-telegram-input'
                    className='authentication-input'
                    hidden
                  />
                  {/* <!--input-for-controlling-the-funtionality--> */}
                  <input
                    type='checkbox'
                    id='pop-up-instagram-input'
                    className='authentication-input'
                    hidden
                  />
                  {/* <!--pop-up-window-wrapper--> */}
                  <div className='pop-up-window-wrapper'>
                    {/* <!--name-and-family-name--> */}
                    <div className='pop-up-window name-family-pop-up-window'>
                      {/* <!--text--> */}
                      <h4 class='pop-up-title'>ثبت اطلاعات شناسایی</h4>
                      {/* <!--text--> */}
                      <p class='pop-up-text'>
                        لطفا اطلاعات شناسایی خود را وارد کنید. نام و نام
                        خانوادگی شما باید با اطلاعاتی که وارد می‌کنید همخوانی
                        داشته باشند.
                      </p>
                      {/* <!--content--> */}
                      <div className='pop-up-form-wrapper'>
                        <form action='#' className='pop-up-form-v4'>
                          {/* <!--input--> */}
                          <input
                            type='text'
                            name='name'
                            className='pop-up-form-input'
                            placeholder='نام و نام خانوادگی:'
                          />
                          {/* <!--input--> */}
                          <input
                            type='number'
                            name='id-number'
                            className='pop-up-form-input'
                            placeholder='کد ملی : '
                          />
                          {/* <!--buttons-wrapper--> */}
                          <div className='pop-up-buttons-wrapper'>
                            {/* <!--button--> */}
                            <label
                              for='name-family-name-input'
                              className='pop-up-button pop-up-white-button'
                            >
                              بازگشت و انصراف
                            </label>
                            {/* <!--button--> */}
                            <button
                              type='submit'
                              className='pop-up-button pop-up-blue-button'
                            >
                              ویرایش و تغییر اطلاعات
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* <!--number--> */}
                    <div className='pop-up-window pop-up-number-window'>
                      {/* <!--text--> */}
                      <h4 className='pop-up-title'>ثبت | تغییر شماره موبایل</h4>
                      {/* <!--text--> */}
                      <p className='pop-up-text'>
                        لطفا شماره تماس با نام و نام خانوادگی و کدملی شما مطابقت
                        داشته باشد .
                      </p>
                      {/* <!--content--> */}
                      <div className='pop-up-form-wrapper'>
                        <form action='#' class='pop-up-form-v4'>
                          {/* <!--input--> */}
                          <input
                            type='number'
                            name='id-number'
                            className='pop-up-form-input'
                            placeholder='شماره موبایل : '
                          />
                          {/* <!--text--> */}
                          <p className='pop-up-text pop-up-text-v2'>
                            برای ثبت این شماره باید آن را تایید کنید.
                          </p>
                          {/* <!--buttons-wrapper--> */}
                          <div className='pop-up-buttons-wrapper'>
                            {/* <!--button--> */}
                            <label
                              for='pop-up-number-input'
                              className='pop-up-button pop-up-white-button'
                            >
                              بازگشت و انصراف
                            </label>
                            {/* <!--button--> */}
                            <button
                              type='submit'
                              className='pop-up-button pop-up-blue-button'
                            >
                              ویرایش و تغییر اطلاعات
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* <!--email--> */}
                    <div className='pop-up-window email-pop-up-window'>
                      {/* <!--text--> */}
                      <h4 className='pop-up-title'>ویرایش ایمیل</h4>
                      {/* <!--text--> */}
                      <p className='pop-up-text'>
                        لطفا ایمیل خود را با دقت وارد کنید
                      </p>
                      {/* <!--content--> */}
                      <div className='pop-up-form-wrapper'>
                        <form action='#' className='pop-up-form-v4'>
                          {/* <!--input--> */}
                          <input
                            type='email'
                            name='email'
                            className='pop-up-form-input'
                            placeholder='ایمیل :'
                          />
                          {/* <!--buttons-wrapper--> */}
                          <div className='pop-up-buttons-wrapper'>
                            {/* <!--button--> */}
                            <label
                              for='email-pop-up-input'
                              className='pop-up-button pop-up-white-button'
                            >
                              بازگشت و انصراف
                            </label>
                            {/* <!--button--> */}
                            <button
                              type='submit'
                              className='pop-up-button pop-up-blue-button'
                            >
                              ویرایش و تغییر اطلاعات
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* <!--name-and-family-name--> */}
                    <div className='pop-up-window id-number-pop-up-window'>
                      {/* <!--text--> */}
                      <h4 className='pop-up-title'>ثبت اطلاعات شناسایی</h4>
                      {/* <!--text--> */}
                      <p className='pop-up-text'>
                        لطفا اطلاعات شناسایی خود را وارد کنید. نام و نام
                        خانوادگی شما باید با اطلاعاتی که وارد می‌کنید همخوانی
                        داشته باشند.
                      </p>
                      {/* <!--content--> */}
                      <div className='pop-up-form-wrapper'>
                        <form action='#' className='pop-up-form-v4'>
                          {/* <!--input--> */}
                          <input
                            type='text'
                            name='name'
                            className='pop-up-form-input'
                            placeholder='نام و نام خانوادگی:'
                          />
                          {/* <!--input--> */}
                          <input
                            type='number'
                            name='id-number'
                            className='pop-up-form-input'
                            placeholder='کد ملی : '
                          />
                          {/* <!--buttons-wrapper--> */}
                          <div class='pop-up-buttons-wrapper'>
                            {/* <!--button--> */}
                            <label
                              for='pop-up-id-number-input'
                              className='pop-up-button pop-up-white-button'
                            >
                              بازگشت و انصراف
                            </label>
                            {/* <!--button--> */}
                            <button
                              type='submit'
                              className='pop-up-button pop-up-blue-button'
                            >
                              ویرایش و تغییر اطلاعات
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* <!--telegram--> */}
                    <div className='pop-up-window telegram-pop-up-window'>
                      {/* <!--text--> */}
                      <h4 className='pop-up-title'>
                        ثبت | ویرایش شبکه اجتماعی
                      </h4>
                      {/* <!--text--> */}
                      <p className='pop-up-text'>
                        لطفا آیدی تلگرام خود را با دقت وارد کنید
                      </p>
                      {/* <!--content--> */}
                      <div className='pop-up-form-wrapper'>
                        <form action='#' className='pop-up-form-v4'>
                          {/* <!--input--> */}
                          <input
                            type='text'
                            name='text'
                            className='pop-up-form-input'
                            placeholder='تلگرام :'
                          />
                          {/* <!--buttons-wrapper--> */}
                          <div className='pop-up-buttons-wrapper'>
                            {/* <!--button--> */}
                            <label
                              for='pop-up-telegram-input'
                              className='pop-up-button pop-up-white-button'
                            >
                              بازگشت و انصراف
                            </label>
                            {/* <!--button--> */}
                            <button
                              type='submit'
                              className='pop-up-button pop-up-blue-button'
                            >
                              ویرایش و تغییر اطلاعات
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* <!--instagram--> */}
                    <div className='pop-up-window instagram-pop-up-window'>
                      {/* <!--text--> */}
                      <h4 className='pop-up-title'>
                        ثبت | ویرایش شبکه اجتماعی
                      </h4>
                      {/* <!--text--> */}
                      <p className='pop-up-text'>
                        لطفا آیدی اینستاگرام خود را با دقت وارد کنید
                      </p>
                      {/* <!--content--> */}
                      <div className='pop-up-form-wrapper'>
                        <form action='#' className='pop-up-form-v4'>
                          {/* <!--input--> */}
                          <input
                            type='text'
                            name='text'
                            className='pop-up-form-input'
                            placeholder='اینستاگرام :'
                          />
                          {/* <!--buttons-wrapper--> */}
                          <div className='pop-up-buttons-wrapper'>
                            {/* <!--button--> */}
                            <label
                              for='pop-up-instagram-input'
                              className='pop-up-button pop-up-white-button'
                            >
                              بازگشت و انصراف
                            </label>
                            {/* <!--button--> */}
                            <button
                              type='submit'
                              className='pop-up-button pop-up-blue-button'
                            >
                              ویرایش و تغییر اطلاعات
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <ToastContainer toastClassName='toast-right' />
    </div>
  )
}

export default Authentication
